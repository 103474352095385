import styled from "styled-components";
export const ModalView = styled.div`
.modal-style{
  width: 1200px !important;
  transform-origin: 24px 23px;
  button {
    .success{
      background: #07A568 !important;
    }
  }
.ant-modal-footer{
  button {
    .success{
      background: #07A568 !important;
    }
  }

}
}
`;
export const exportCSV = styled.div`
  button {
    background-color: #07a568;
    border-radius: 5px;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
    cursor: pointer;
  }
`;
export const exportRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap:10px;
`;
export const Head = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px;
  background-color: #1F5454;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{
    margin-right:10px;
  }
`;

export const Content = styled.div`
  color: #444444;
  font-size: 18px;
  font-weight: 600;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0px;
  margin-bottom: 10px;
  max-height: 500px !important;
  
  .global-table {
    table {
      width: 100% !important;
      border-radius: 0px;
    }
    .ant-table-header {
      border-radius: 0px;
    }
    .ant-table-body {
      max-height: 400px !important;
      overflow-y: scroll !important;
    }
    thead th {
      background: #f3fdf8;
      font-weight: 600;
      font-size: 16px;
      color: #1f5454;
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-color: #07a568;
      border-radius: 0px !important;
      padding: 9px 10px;
      &::before {
        width: 0px !important;
      }
    }
    tbody {
      border: 1px solid #07a568;
    }
    tbody td {
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      border-bottom: 0px;
      height: 20px;
      padding: 9px 10px;
    }
    .ant-table-footer {
      // position: sticky;
      // bottom: 0;
      position: fixed;
      top: calc(100vh - 120px);
      width: 100%;
      background: #fff;
      padding: 0px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    }
  }
`;
