import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  // height: calc(100vh - 175px);
  height: 100% !important;
`;

export const BlockContainer = styled.div`
  width:100%;
  padding-top: 68px;
  padding-left: 80px;
  // margin: 68px 0 0 80px !important;
`;

export const TopPanelContainer = styled.div`
  width: 100%;
  background: #FFFFFF;
  height: 60px;
  border-radius: 8px;
  padding: 18px 20px;
  position: relative;
  margin-bottom: 18px !important;
`;

export const MainTitleStyle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
`;

export const SelectBoxPanel = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space:nowrap;
  column-gap:15px;
  position: absolute;
  top: 5px;
  right: 4px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;

export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;

export const RowDivider = styled.div`
  width: 100%;
  border: 1px dashed rgb(34, 34, 34, 0.1);
  margin: 20px 0px;
`;

export const CenterSpin = styled.div`
  position: absolute;
  left: 45%;
  right: 45%;
  top: 45%;
  margin: auto;
`;

export const GraphicalSection = styled.div`
  min-height: 423px;
  width: 100%;
  background: #FFFFFF;
  height: auto;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #444444 !important;
    font-weight: 700;
    font-size: 18px;
  }
  .ant-tabs .ant-tabs-tab:first-child {
    padding-left: 0 !important;
  }
  .ant-tabs .ant-tabs-tab {
    color: #CCCCCC !important;
    font-weight: 700;
    font-size: 18px;
    padding: 0 20px;
    border-right: 1px solid #F5F5F5;
    margin: 0 !important;
  }
  .ant-tabs .ant-tabs-tab:nth-last-child(2){
    border: none !important;
  }
  .ant-tabs .ant-tabs-nav {
    margin-bottom: 0  !important;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-extra-content {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .ant-tabs {
    width: 100% !important;
  }
  .ant-select-selector {
    padding-left: 0 !important;
  }
  .ant-picker-range {
    width: 339px !important;
  }
  .divider {
    top: -0.4em !important;
    height: 1.6em !important;
    background: #CCCCCC !important;
  }
`;

export const TopChartIconSelection = styled.div`
  // position: absolute;
  // top: 15px;
  // right: 185px;
  box-shadow: 0px 2px 10px 0px #7C8DB51F;
  border-radius: 4px;
  padding-top: 5px;
  .icon {
    font-size: 24px !important;
    margin: 0 18px;
  }
`;

export const ChartSelectBoxPanel = styled.div`
// position: absolute;
// top: 20px;
// right: 10px;
  .ant-select {
    height: 24px !important;
  }
`;
export const NoData = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #cccccc !important;
  font-weight: 700;
  font-size: 18px;
`;