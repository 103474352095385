import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  // margin-top: 20px;
  .common-table {
    thead tr th {
      background: #f3fdf8;
      border: 0px;
    }
  }
`;

export const BlockContainer = styled.div`
width:100%;
padding-top: 68px;
padding-left: 80px;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px dashed rgb(34, 34, 34, 0.1);
`;

export const RowDivider = styled.div`
  width: 100%;
  border: 1px dashed rgb(34, 34, 34, 0.1);
  margin: 20px 0px;
`;

// export const SubHead = styled.div`
//   background: #ffffff;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
//   border-radius: 8px;
//   padding-top: 30px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 10px;
// `;

export const BoxRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
`;
export const BoxRowMulti = styled.div`
  display: flex;
  gap: 10px;
`;
export const BoxRowFull = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
`;
export const LeftBox = styled.div`
  background-color: #fff;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 20px;
  &.other {
    padding: 0px;
  }
  min-height: 240px;
`;

export const BoxHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const RightBox = styled.div`
  background-color: #fff;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 20px;
  width: 50%;
`;

export const TableContainer = styled.div`
width:100%;
}
`;
export const TableHead = styled.div`
  background-color: #fff;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-height: 70px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 20px;
  gap: 10px;
  white-space: nowrap;
`;

export const breadCrumb = styled.div`
  display: flex;
`;

export const breadText = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #444;
  a {
    color: #1f5454;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  font-size: 18px;
  color: #666666;
  &.card-title {
    padding-left: 10px;
    padding-top: 10px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;
export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;
export const statusText = styled.div<{
  status?: number;
}>`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => (props.status !== 0 ? "#ffffff" : "#BD861C")};
`;

export const searchBox = styled.div`
  width: 1000%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  background-color: #ebebeb;
  background-image: none;
  border-radius: 6px;
  transition: all 0.2s;
  white-space: nowrap;
  input {
    background: #f8f8f8 !important;
    border-radius: 6px;
    border-color: #ebebeb;
  }
`;
export const viewMore = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-align: center;
  text-decoration: underline;
  width: 100%;
  margin: 10px 0px;
`;
export const TableView = styled.div`
  // margin-top: 10px;
  height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TextStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  margin-right: 20px;
  margin-bottom: 10px;
`;
export const exportCSV = styled.div`
  button {
    background-color: #07a568;
    border-radius: 6px;
    color: white !important;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    height: 38px;
    min-width: 120px;
  }
`;
export const UnderlineText = styled.div`
  text-decoration: underline;
  text-decoration-color: #444;
  a {
    color: #444444;
  }
  a:hover {
    color: #000;
  }
`;
export const EmptyImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  height: 675px;
  color: #00000073;
`;
export const pageText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 80%;
  padding: 5px 10px;
`;