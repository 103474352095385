import { useEffect, useState } from "react";
import GlobalTable from "../../common/global-table/GlobalTable";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./styles";
import { Select, Tag, DatePicker, Modal, Button, Table } from "antd";
import type { DatePickerProps, GetProps, PaginationProps } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import axios from "../../common/api/axios";
import moment from "moment";
import { ReactComponent as Empty } from "./icons/empty.svg";
import { ReactComponent as Export } from "./icons/export.svg";
import { ReactComponent as Close } from "./icons/close.svg";
import { ReactComponent as SelectIcon } from "./icons/select.svg";
import CsvDownloader from "react-csv-downloader";
import PickupSKUs from "./components/pickup-Skus/PickupSKUs"

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;


interface DataType {
  orderReferenceId: string;
  fulfillmentStart: string;
  pickStart: string;
  fulfillmentComp: string;
  tat: string;
  status: number;
  orderDate: string;
  history: any;
  fulfillment: any;
  customerName: string;
  skuID: string;
  skuDescription: string;
  quantity: number;
  externalFulfillment: any;
}

export default function OrderListing() {
  let storageFilter = localStorage.getItem('filter') === null ? "today" : localStorage.getItem('filter') ;
  const navigate = useNavigate();
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const [exportDataSource, setExportDataSource] = useState([]);
  const [startDate, setStartDate] = useState(""
    //moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    //moment().subtract(7,'d').format('YYYY-MM-DD HH:mm:ss')
  );
  const [endDate, setEndDate] = useState(""
    //moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
    //moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
  );
  const [dateRangePickerVisibility, setDateRangePickerVisibility] =
    useState(false);
  const [filter, setFilter] = useState(storageFilter);
  const [loader, setLoader] = useState(true);
  const [viewLink, setViewLink] = useState(true);
  const [isOkPopup, setIsOkPopup] = useState(false);
  const [ageMinute, setAgeMinute] = useState(1440);
  const [orderLength, setOrderLength] = useState(0);

  const { RangePicker } = DatePicker;
  const exportSampleData:any = [
    {
      "orderReferenceId": "Order2",
      "orderDate": "2024-04-03T10:12:19Z",
      "amount": 45.7,
      "priorityClass": "A",
      "waveId": "2",
      "fulfillmentId": "FID13032024093445",
      "orderedBy": "S Rajagopalan",
      "isFulfilled": true,
      "isFulfilledExternally": false,
      "fulfillment": {
        "isPartial": false,
        "items": [
          {
            "skuId": "SBB002",
            "itemId": 262130049
          },
          {
            "skuId": "SBB002",
            "itemId": 711180391
          },
          {
            "skuId": "SBB002",
            "itemId": 516872959
          },
          {
            "skuId": "SBB002",
            "itemId": 460640610
          },
          {
            "skuId": "SBB002",
            "itemId": 203039427
          },
          {
            "skuId": "SBB002",
            "itemId": 362839118
          },
          {
            "skuId": "SAV004",
            "itemId": 978711541
          },
          {
            "skuId": "SAV004",
            "itemId": 195020046
          },
          {
            "skuId": "SAV004",
            "itemId": 512862953
          },
          {
            "skuId": "SAV004",
            "itemId": 694347560
          },
          {
            "skuId": "SAV005",
            "itemId": 975319235
          },
          {
            "skuId": "SAV005",
            "itemId": 357654460
          },
          {
            "skuId": "SAV005",
            "itemId": 109763077
          },
          {
            "skuId": "SAV005",
            "itemId": 398161753
          },
          {
            "skuId": "SAV005",
            "itemId": 839917475
          },
          {
            "skuId": "SAV003",
            "itemId": 105307197
          },
          {
            "skuId": "SAV003",
            "itemId": 986977099
          },
          {
            "skuId": "SAV003",
            "itemId": 597795336
          },
          {
            "skuId": "SAV003",
            "itemId": 75074723
          },
          {
            "skuId": "SAV003",
            "itemId": 482049644
          },
          {
            "skuId": "SAV003",
            "itemId": 268108263
          },
          {
            "skuId": "SAV003",
            "itemId": 224033079
          },
          {
            "skuId": "SAV003",
            "itemId": 679994070
          },
          {
            "skuId": "ADK003",
            "itemId": 397243128
          },
          {
            "skuId": "ADK003",
            "itemId": 268999851
          },
          {
            "skuId": "ADK003",
            "itemId": 276014481
          },
          {
            "skuId": "ADK003",
            "itemId": 505709955
          },
          {
            "skuId": "ADK003",
            "itemId": 714874261
          },
          {
            "skuId": "ADK003",
            "itemId": 71167176
          },
          {
            "skuId": "ADK001",
            "itemId": 742831958
          },
          {
            "skuId": "ADK001",
            "itemId": 874554534
          },
          {
            "skuId": "ADK001",
            "itemId": 959602494
          },
          {
            "skuId": "ADK001",
            "itemId": 372016927
          },
          {
            "skuId": "ADK001",
            "itemId": 669496497
          },
          {
            "skuId": "SBB003",
            "itemId": 379480820
          },
          {
            "skuId": "SBB003",
            "itemId": 137771803
          },
          {
            "skuId": "SBB003",
            "itemId": 449935392
          },
          {
            "skuId": "SBB003",
            "itemId": 92764043
          },
          {
            "skuId": "SBB001",
            "itemId": 234155196
          },
          {
            "skuId": "SBB001",
            "itemId": 14650866
          },
          {
            "skuId": "SBB001",
            "itemId": 251339707
          },
          {
            "skuId": "SBB001",
            "itemId": 6551506
          },
          {
            "skuId": "SBB001",
            "itemId": 515905599
          },
          {
            "skuId": "SBB001",
            "itemId": 479889064
          },
          {
            "skuId": "SBB001",
            "itemId": 998936281
          },
          {
            "skuId": "SBB001",
            "itemId": 678184243
          },
          {
            "skuId": "ADK002",
            "itemId": 319150700
          },
          {
            "skuId": "ADK002",
            "itemId": 389292743
          },
          {
            "skuId": "ADK002",
            "itemId": 514501049
          },
          {
            "skuId": "ADK002",
            "itemId": 820470316
          },
          {
            "skuId": "ADK002",
            "itemId": 624240171
          },
          {
            "skuId": "ADK004",
            "itemId": 967407181
          },
          {
            "skuId": "ADK004",
            "itemId": 296103245
          },
          {
            "skuId": "ADK004",
            "itemId": 96374608
          },
          {
            "skuId": "ADK004",
            "itemId": 696108923
          },
          {
            "skuId": "ADK004",
            "itemId": 237312936
          },
          {
            "skuId": "ADK004",
            "itemId": 607321807
          },
          {
            "skuId": "ADK004",
            "itemId": 771870813
          },
          {
            "skuId": "ADK002",
            "itemId": 935632067
          },
          {
            "skuId": "ADK002",
            "itemId": 123491662
          },
          {
            "skuId": "ADK002",
            "itemId": 488463791
          },
          {
            "skuId": "ADK002",
            "itemId": 914594803
          },
          {
            "skuId": "ADK002",
            "itemId": 710373403
          },
          {
            "skuId": "ADK002",
            "itemId": 756213624
          },
          {
            "skuId": "ADK002",
            "itemId": 542130273
          },
          {
            "skuId": "ADK002",
            "itemId": 988661571
          },
          {
            "skuId": "ADK002",
            "itemId": 895535786
          },
          {
            "skuId": "ADK002",
            "itemId": 915417546
          },
          {
            "skuId": "ADK002",
            "itemId": 902091469
          },
          {
            "skuId": "ADK002",
            "itemId": 246137981
          },
          {
            "skuId": "ADK002",
            "itemId": 367537698
          },
          {
            "skuId": "ADK002",
            "itemId": 226530531
          },
          {
            "skuId": "ADK002",
            "itemId": 348948663
          }
        ]
      },
      "externalFulfillment": {
        "isPartial": true,
        "skus": [
          {
            "skuId": "ADK001",
            "quantity": 15
          }
        ]
      },
      "orderItems": [
        {
          "skuId": "ADK001",
          "skuName": "Adukale Kodubale (Kayi) 180 Gms",
          "quantity": 20,
          "isExternal": true,
          "price": 3.99,
          "isFlagged": false
        },
        {
          "skuId": "SBB003",
          "skuName": "Sreenivasa Brahmins Bakery Salted Chintamani Peanuts 200 Gms",
          "quantity": 4,
          "isExternal": false,
          "price": 3.99,
          "isFlagged": false
        },
        {
          "skuId": "SAV003",
          "skuName": "Shastha - Appalam #3 (225 Gms)",
          "quantity": 8,
          "isExternal": false,
          "price": 7.29,
          "isFlagged": false
        },
        {
          "skuId": "ADK002",
          "skuName": "Adukale Onion Kodubale 180 Gms",
          "quantity": 20,
          "isExternal": false,
          "price": 3.99,
          "isFlagged": false
        },
        {
          "skuId": "SBB002",
          "skuName": "Sreenivasa Brahmins Bakery Roasted Red Chilli Chintamani Peanuts 200 Gms",
          "quantity": 6,
          "isExternal": false,
          "price": 4.99,
          "isFlagged": false
        },
        {
          "skuId": "SAV004",
          "skuName": "Shastha - Appalam #4 (180 Gms)",
          "quantity": 4,
          "isExternal": false,
          "price": 3.99,
          "isFlagged": false
        },
        {
          "skuId": "SAV005",
          "skuName": "Shastha - Appalam #5 (150 Gms)",
          "quantity": 5,
          "isExternal": false,
          "price": 6.49,
          "isFlagged": false
        },
        {
          "skuId": "ADK003",
          "skuName": "Adukale Rice Kodubale Rings 180 Gms",
          "quantity": 6,
          "isExternal": false,
          "price": 3.99,
          "isFlagged": false
        },
        {
          "skuId": "ADK004",
          "skuName": "Adukale Methi  Kodubale 180 Gms",
          "quantity": 7,
          "isExternal": false,
          "price": 2.99,
          "isFlagged": false
        },
        {
          "skuId": "SBB001",
          "skuName": "Sreenivasa Brahmins Bakery Green Chilli Chintamani Peanut 200 Gms",
          "quantity": 8,
          "isExternal": false,
          "price": 3.99,
          "isFlagged": false
        }
      ],
      "history": [
        {
          "state": "Created",
          "timeStamp": "2024-03-13T09:32:01.6149456Z"
        },
        {
          "state": "StartedFulfillment",
          "timeStamp": "2024-03-13T12:59:29.7226606Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:29.7226631Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:30.1890178Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:30.637751Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:31.1187794Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:31.7019124Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:32.1627676Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:32.682961Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:33.1225707Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:33.641878Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T12:59:34.079215Z"
        },
        {
          "state": "StartedExternalFulfillment",
          "timeStamp": "2024-03-13T13:00:44.6931218Z"
        },
        {
          "state": "CreatedPickTask",
          "timeStamp": "2024-03-13T13:01:01.2994513Z"
        },
        {
          "state": "Fulfilled",
          "timeStamp": "2024-03-13T13:01:13.3649251Z"
        }
      ]
    },
    {
      "orderReferenceId": "Order5",
      "orderDate": "2024-03-14T10:12:19Z",
      "amount": 6.98,
      "priorityClass": "A",
      "waveId": "0",
      "orderedBy": "Suresh Lakshmanasamy15",
      "isFulfilled": true,
      "isFulfilledExternally": true,
      "fulfillment": {
        "isPartial": true,
        "remarks": "Administrative Action",
        "items": []
      },
      "externalFulfillment": {
        "skus": [
          {
            "skuId": "IMS0039",
            "quantity": 2
          },
          {
            "skuId": "IMS0038",
            "quantity": 1
          }
        ]
      },
     
      "history": [
        {
          "state": "Created",
          "timeStamp": "2024-03-14T06:19:48.0499911Z"
        },
        {
          "state": "StartedFulfillment",
          "timeStamp": "2024-03-14T06:20:47.6774463Z"
        },
        {
          "state": "StartedExternalFulfillment",
          "timeStamp": "2024-03-14T06:20:47.6774473Z"
        },
        {
          "state": "Fulfilled",
          "timeStamp": "2024-03-14T06:20:47.6774474Z"
        },
        {
          "state": "FulfilledExternally",
          "timeStamp": "2024-03-14T06:20:47.6774475Z"
        }
      ]
    }
  ];

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "orderReferenceId",
      key: "orderReferenceId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "orderReferenceId"
        );
        return (
          <Styles.ArrowContainer>
            Order ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.orderReferenceId ?? "").localeCompare(b?.orderReferenceId ?? ""),
      render: (text, record) => (
        <Styles.OrderId>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.OrderId>
      ),
    },
    {
      dataIndex: "date",
      key: "fulfillmentStart",
      width: 190,
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "fulfillmentStart"
        );
        return (
          <Styles.ArrowContainer>
            Fulfillment Started
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        moment(a?.history[1]?.timeStamp).unix() -
        moment(b?.history[1]?.timeStamp).unix(),
      render: (x, val, index) => {
        if (val.history[1]) {
          var localTime = moment.utc(val.history[1].timeStamp).toDate();
          return moment(localTime).format("DD/MM/YYYY HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      dataIndex: "date",
      key: "pickStart",
      width: 150,
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "pickStart"
        );
        return (
          <Styles.ArrowContainer>
            Pick Started
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        moment(a?.history[2]?.timeStamp).unix() -
        moment(b?.history[2]?.timeStamp).unix(),
      render: (x, val, index) => {
        if (val.history[2]) {
          var localTime = moment.utc(val.history[2].timeStamp).toDate();
          return moment(localTime).format("DD/MM/YYYY HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      dataIndex: "date",
      key: "fulfillmentComp",
      width: 200,
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "fulfillmentComp"
        );
        return (
          <Styles.ArrowContainer>Fulfillment Completed</Styles.ArrowContainer>
        );
      },
      render: (x, val, index) => {
        let ind = val.history.length - 1;
        if (val.history[ind]) {
          if (val.history[ind].state === "Fulfilled") {
            var localTime = moment.utc(val.history[ind].timeStamp).toDate();
            return moment(localTime).format("DD/MM/YYYY HH:mm:ss");
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
    {
      key: "tat",
      dataIndex: "tat",
      title: (titleProps) => {
        return <Styles.ArrowContainer>TAT</Styles.ArrowContainer>;
      },
      render: (x, val, index) => {
        let ind = val.history.length - 1;
        if (val.history[ind].state === "Fulfilled") {
          let dateFinal: any = val.history[ind]
            ? new Date(val.history[ind].timeStamp)
            : 0;
          let dateInitial: any = val.history[1]
            ? new Date(val.history[1].timeStamp)
            : 0;
          return ((dateFinal - dateInitial) / 1_000).toFixed(2) + " S";
        } else {
          return "";
        }
      },
    },
    {
      dataIndex: "status",
      key: "status",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Status</Styles.ArrowContainer>;
      },
      render: (x, val, index) => {
        let color = "";
        let text = "";
        let status = val.fulfillment ? val.fulfillment.isPartial : true;
        if (status === false) {
          text = "Completed";
          color = "#E0FBEE";
        } else {
          text = "In Progress";
          color = "#FFEDBD";
        }
        return (
          <Tag color={color} key={status} style={{padding: "5px 14px", borderRadius: 20}}>
            <Styles.statusText status={status}>{text}</Styles.statusText>
          </Tag>
        );
      },
    },
    {
      dataIndex: "date",
      key: "orderDate",
      
      width: 150,
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "orderDate"
        );
        return (
          <Styles.ArrowContainer>
            Order Date
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        moment(a?.orderDate).unix() - moment(b?.orderDate).unix(),
      render: (x, val, index) => {
        if (val.orderDate) {
          var localTime = moment.utc(val.orderDate).toDate();
          return moment(localTime).format("DD/MM/YYYY HH:mm:ss");
        } else {
          return "";
        }
      },
      //defaultSortOrder: "descend",
    },
  ];
  const exportcolumns = [
    {
      id: "orderReferenceId",
      displayName: "Order ID",
    },
    {
      id: "customerName",
      displayName: "Customer Name",
    },
    {
      id: "skuID",
      displayName: "sku ID",
    },
    {
      id: "skuDescription",
      displayName: "SKU Description",
    },
    {
      id: "quantity",
      displayName: "quantity",
    }
  ];
  const exportColumn: ColumnsType<DataType> = [
    {
      dataIndex: "orderReferenceId",
      key: "orderReferenceId",
      title: (titleProps) => {
        return (
          <Styles.ArrowContainer>
            Order ID
          </Styles.ArrowContainer>
        );
      }
    },
    {
      dataIndex: "customerName",
      key: "customerName",
      title: (titleProps) => {
        return (
          <Styles.ArrowContainer>
            Customer Name
          </Styles.ArrowContainer>
        );
      }
    },
    {
      dataIndex: "skuID",
      key: "skuID",
      title: (titleProps) => {
        return (
          <Styles.ArrowContainer>
            SKU ID
          </Styles.ArrowContainer>
        );
      }
    },
    {
      dataIndex: "skuDescription",
      key: "skuDescription",
      title: (titleProps) => {
        return (
          <Styles.ArrowContainer>
            SKU Description
          </Styles.ArrowContainer>
        );
      }
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: (titleProps) => {
        return (
          <Styles.ArrowContainerCenter>
            <center>Quantity</center>
          </Styles.ArrowContainerCenter>
        );
      },
      render: (text) => {
        return (<center>{text}</center>)
      }
    },
    
  ];

  const getOrderDetailsData = async () => {
    try {
      const res = await axios.get(`/Wms/orders/${startDate}/${endDate}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setFilter("");
        setStartDate("");
        setEndDate("");
       let sortData = res.data.sort(custom_sort);
        if (res.data && res.data.length <= 10) {
          setViewLink(false);
        } else {
          setViewLink(true);
        }
        setAllDataSource(sortData);
        let data = sortData.slice(0, 10);
        setSearchDataSource(data);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  };
  function custom_sort(a:any, b:any) {
    //let status = val.fulfillment ? val.fulfillment.isPartial : true;
    return Number(b?.fulfillment?.isPartial) - Number(a?.fulfillment?.isPartial)
    //return a?.fulfillment.isPartial?.localeCompare(b?.fulfillment.isPartial);
    //return moment(b?.fulfillment.isPartial).unix() - moment(a?.fulfillment.isPartial).unix()
    //return new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
  }
  const getExportDetailsData = async () => {
    try {
      const res = await axios.get(`/Wms/externallyUnfulfilledOrdersByAge/${ageMinute}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        let outArr:any = [];
        res.data.map((val:any) => {
        if(val.orderItems)
        {
          val.orderItems.map((skuVal:any) => {
            if(skuVal.isExternal == true || skuVal.isFlagged == true)
            {
              outArr.push({
                "orderReferenceId": val.orderReferenceId,
                "customerName": val.orderedBy,
                "skuID": skuVal.skuId,
                "skuDescription": skuVal.skuName,
                "quantity": skuVal.quantity
      
              })
            }
          })
        }
        
       })
       setOrderLength(res.data.length);
       setExportDataSource(outArr);
      }
    } catch (error: any) {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (filter !== "") {
      handleWeekChange(filter);
      let minutes = filter == 'today' ? 1440 : filter == 'week' ? 10080 : filter == 'month' ? 43200 : 1440;
      setAgeMinute(minutes)
      //getOrderDetailsData();
    }
  }, [filter]);
  useEffect(() => {
    if(startDate)
    getOrderDetailsData();
  }, [startDate]);

  useEffect(() => {
    getExportDetailsData();
  }, [ageMinute]);

  const redirectDetails = (clickedItem: string) => {
    navigate(`/order_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };

  const handleWeekChange = (value: any) => {
    setLoader(true);
    let startOfWeek = "";
    let endOfWeek = "";
    setDateRangePickerVisibility(false);
    if (value === "week") {
      startOfWeek = moment().subtract(7,'d').format('YYYY-MM-DD HH:mm:ss');
      //startOfWeek = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "month") {
      startOfWeek = moment().subtract(30,'d').format('YYYY-MM-DD HH:mm:ss');
      //startOfWeek = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "today") {
      startOfWeek = moment().subtract(24, 'hours').format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "custom") {
      setLoader(false);
      setDateRangePickerVisibility(true);
    } else {
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
      setFilter(value);
      localStorage.setItem('filter', value)
    }
  };

  const handleDatePicker = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    setFilter("custom");
  };
  const openModal = () => {
    setIsOkPopup(true);
  }
  const isCloseOkPopup = () => {
    //ohHandleOkItem(selectedOrder.id);
    setIsOkPopup(false);
  };
  const isCancelOkPopup = () => {
    setIsOkPopup(false);
  };
  const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items of ${orderLength} orders`;

  return (
    <Styles.Container>
      <Sidebar />
      <Styles.BlockContainer>
      <GlobalTable
        emptyText={{emptyText: (
          <span>
            <p>
              <Empty />
            </p>
            No matching records. Please try changing the query and/or date filter
          </span>
        )}}
        uniqueRowKey="orderID"
        column={columns}
        alldata={allDataSource}
        data={searchDataSource}
        viewlink={viewLink}
        loader={loader}
        headerAction={
          <>
            <Styles.ExportText>
            <a onClick={openModal}>Pickup SKUs </a> <span> <Export /> </span>
            </Styles.ExportText>
            <Select
              suffixIcon={<SelectIcon />}
              variant="borderless"
              style={{ textAlign: "right" }}
              className="common arrow-right"
              popupClassName="pick-dropdown"
              defaultValue={filter}
              onChange={handleWeekChange}
              options={[
                { value: "today", label: "Last 24 hrs" },
                { value: "week", label: "Last 7 days" },
                { value: "month", label: "Last 30 days" },
                { value: "custom", label: "Custom" },
              ]}
            />
            {dateRangePickerVisibility && (
              <RangePicker showTime onChange={handleDatePicker} />
            )}
          </>
        }
      />
      <PickupSKUs isOkPopup={isOkPopup} isCloseOkPopup={isCloseOkPopup} isCancelOkPopup={isCancelOkPopup} exportDataSource={exportDataSource} exportcolumns={exportcolumns} exportColumn={exportColumn} loader={loader} showTotal={showTotal} />
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
