import * as Styles from "./styles";

export interface KpiCardsProps {
    kpiInfo: any;
  }

export default function KpiCards({
    kpiInfo
}: KpiCardsProps) {
    let OrderProcessed:any = kpiInfo.OrderCreatedEvent - kpiInfo.OrderFulfilledEvent;
    return (
        <Styles.SnippetSection>
            <Styles.SnippetBox>
                <Styles.SnippetTitle>Orders Fulfilled</Styles.SnippetTitle>
                <Styles.SnippetValue>{kpiInfo.OrderCreatedEvent || 0}</Styles.SnippetValue>
            </Styles.SnippetBox>

            <Styles.SnippetBox>
                <Styles.SnippetTitle>Pick Count</Styles.SnippetTitle>
                <Styles.SnippetValue>{kpiInfo.PickCompletedEvent || 0}</Styles.SnippetValue>
            </Styles.SnippetBox>

            <Styles.SnippetBox>
                <Styles.SnippetTitle>Orders To Be Processed</Styles.SnippetTitle>
                <Styles.SnippetValue>{OrderProcessed || 0}</Styles.SnippetValue>
            </Styles.SnippetBox>

            <Styles.SnippetBox>
                <Styles.SnippetTitle>Non - Zero Quantity SKUs</Styles.SnippetTitle>
                <Styles.SnippetValue>{kpiInfo.nonZeroQty || 0}</Styles.SnippetValue>
            </Styles.SnippetBox>
        </Styles.SnippetSection>
    );
}


