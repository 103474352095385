import * as Styles from "./styles";
import { useEffect, useState } from "react";
import { Select, Space, Table, Tag, Input } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { JsxElement } from "typescript";
import { ReactComponent as Filter } from "./icons/filter.svg";
import moment from "moment";

export interface GlobalTableProps {
  column: any;
  alldata?: any;
  data: any;
  viewlink: any;
  loader: any;
  headerAction?: JsxElement | string | any;
  uniqueRowKey?: string;
  isSearchBoxHided?: boolean;
  emptyText?: JsxElement | any;
  viewMoreSlot?: any;
}
export default function GlobalTable({
  column,
  alldata,
  data,
  viewlink,
  loader,
  headerAction,
  uniqueRowKey,
  isSearchBoxHided,
  emptyText,
  viewMoreSlot
}: GlobalTableProps) {
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [finalRes, setFinalRes] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [paginationText, setPaginationText] = useState("(Filtered from total entries");
  const [viewLink, setViewLink] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [viewCnt, setViewCnt] = useState(20);
  const [manualMapRes, setManualMapRes] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setSearchDataSource(data);
    } else {
      setSearchDataSource([]);
      setViewLink(false);
    }
    if (viewlink) {
      setViewLink(viewlink);
    }
  }, [data, viewlink]);

  const viewMore = () => {
    if (locationSearch == "") {
      let _data = alldata.slice(0, viewCnt);
      if (viewCnt >= alldata.length) {
        setViewLink(false);
      }
      setSearchDataSource(_data);
    } else {
      let _data = finalRes.slice(0, viewCnt);
      if (viewCnt >= finalRes.length) {
        setViewLink(false);
      }
      setSearchDataSource(_data);
    }
    setViewCnt(viewCnt + 10);
  };

  useEffect(() => {
    let lowercasedFilter = locationSearch.toLowerCase();
    var arrList = columnList
      .map(function (value: string) {
        return value.toLowerCase();
      })
      .sort();
    var arrList1 = columnList
      .map(function (value: string) {
        return `${value.toLowerCase()} `;
      })
      .sort();
    if (lowercasedFilter.includes(":")) {
      let splitText = lowercasedFilter.split(" : ");
      let leftText = splitText[0];
      let rightText = splitText[1];
      if (arrList.includes(leftText) || arrList1.includes(leftText)) {
        const filteredData = manualMapRes.filter((item: any) => {
          return Object.keys(item).some((key) => {
            if (item[key] === true || item[key] === false) {
              item[key] = JSON.stringify(item[key]);
            }
            if (
              item[key] &&
              key.toLowerCase() == leftText.split(" ").join("").trim()
            ) {
              let temp = `${item[key]}`;
              return temp.toLowerCase().includes(rightText.trim());
            }
            return false;
          });
        });
        let finalRes: any;
        if (uniqueRowKey == "orderID") {
          let arrIds: any = filteredData.map((o: any) => o.orderId);
          finalRes = alldata.filter((item: any) => {
            return arrIds.indexOf(item.orderReferenceId) > -1;
          });
        } else if (uniqueRowKey == "binId") {
          let arrIds: any = filteredData.map((o: any) => o.binId);
          finalRes = alldata.filter((item: any) => {
            return arrIds.indexOf(item.binId) > -1;
          });
        } else if (uniqueRowKey == "id") {
          let Ids: any = filteredData.map((o: any) => o.id);
          finalRes = alldata.filter((item: any) => {
            return Ids.indexOf(item.id) > -1;
          });
        }
        else if (uniqueRowKey == "skuId") {
          let Ids: any = filteredData.map((o: any) => o.skuId);
          finalRes = alldata.filter((item: any) => {
            return Ids.indexOf(item.skuId) > -1;
          });
        }
        if (rightText !== "" && finalRes && finalRes.length <= 10) {
          setViewLink(false);
        } else {
          setViewLink(true);
        }
        if (rightText !== "") {
          setPaginationText("(Filtered from total entries");
          setTimeout(() => {
            setFinalRes(finalRes);
          }, 500);
        } else {
          setTimeout(() => {
            setSearchDataSource([]);
            setViewLink(false);
            setFinalRes([]);
          }, 500);
        }
        let _data = finalRes.slice(0, 10);
        setSearchDataSource(_data);
        setViewCnt(20);
      } else {
        globalSearch(lowercasedFilter);
      }
    } else {
      globalSearch(lowercasedFilter);
    }
  }, [locationSearch]);

  const globalSearch = (lowercasedFilter: any) => {
    setPaginationText("(Filtered from total entries");
    const filteredData = manualMapRes.filter((item: any) => {
      return Object.keys(item).some((key) => {
        if (item[key] === true || item[key] === false) {
          item[key] = JSON.stringify(item[key]);
        }
        if (item[key]) {
          let temp = `${item[key]}`;
          return temp.toLowerCase().includes(lowercasedFilter);
        }
        return false;
      });
    });
    let finalRes: any;
    if (uniqueRowKey == "orderID") {
      let arrIds: any = filteredData.map((o: any) => o.orderId);
      finalRes = alldata.filter((item: any) => {
        return arrIds.indexOf(item.orderReferenceId) > -1;
      });
    } else if (uniqueRowKey == "binId") {
      let arrIds: any = filteredData.map((o: any) => o.binId);
      finalRes = alldata.filter((item: any) => {
        return arrIds.indexOf(item.binId) > -1;
      });
    } else if (uniqueRowKey == "id") {
      let Ids: any = filteredData.map((o: any) => o.id);
      finalRes = alldata.filter((item: any) => {
        return Ids.indexOf(item.id) > -1;
      });
    }
    else if (uniqueRowKey == "skuId") {
      let Ids: any = filteredData.map((o: any) => o.skuId);
      finalRes = alldata.filter((item: any) => {
        return Ids.indexOf(item.skuId) > -1;
      });
    }

    if (finalRes && finalRes.length <= 10) {
      setViewLink(false);
    } else {
      setViewLink(true);
    }
      setFinalRes(finalRes);
    let viewcount = 10;
    if(finalRes)
    {
      viewcount = finalRes.length > viewCnt - 10 ? viewCnt - 10  : 10; 
    }
    let _data = finalRes ? finalRes.slice(0, viewcount): [];
    setSearchDataSource(_data);
    setViewCnt(viewcount+10);
  };

  const handleCategoryNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value;
    if (newValue.trim().length === 0) {
      setTimeout(() => {
        setPaginationText("");
        setFinalRes([]);
      }, 500);
      if (alldata && alldata.length <= 10) {
        setViewLink(false);
      } else {
        setViewLink(true);
      }
      let _data = alldata.slice(0, 10);
      setSearchDataSource(_data);
      setLocationSearch("");
      setViewCnt(20);
    } else {
      let tempArr: any = [];
      for (let i = 0; i < alldata.length; i++) {
        let tempObj: any = {};
        let info: any = alldata[i];
        if (uniqueRowKey == "orderID") {
          let ind = info.history.length - 1;
          let dateFinal: any = info.history[ind]
            ? new Date(info.history[ind].timeStamp)
            : 0;
          let dateInitial: any = info.history[1]
            ? new Date(info.history[1].timeStamp)
            : 0;
          let status: any = info.fulfillment
            ? info.fulfillment.isPartial
            : true;
          tempObj = {
            orderId: info.orderReferenceId,
            fulfillmentStarted: moment(
              info.history[1]?.timeStamp &&
              info.history[1]?.timeStamp
            ).format("DD/MM/YYYY HH:mm:ss"),
            pickStarted: moment(
              info.history[2]?.timeStamp &&
              info.history[2]?.timeStamp
            ).format("DD/MM/YYYY HH:mm:ss"),
            fulfillmentCompleted: moment(
              info.history[info.history.length - 1]?.timeStamp &&
              info.history[info.history.length - 1]?.timeStamp
            ).format("DD/MM/YYYY HH:mm:ss"),
            tat: ((dateFinal - dateInitial) / 1_000).toFixed(2) + " S",
            status: status === false ? "Completed" : "In Progress",
            orderDate: moment(info.orderDate).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
          };
          tempArr.push(tempObj);
        } else if (uniqueRowKey == "binId") {
          tempObj = {
            binId: info.binId,
            skuId: info.items && info.items[0] && info.items[0].skuId != "" ? info.items[0].skuId : "",
            slotAssigned: info.slotAssigned ? info.slotAssigned : false,
            decommissioned: info.isDecommissioned ? info.isDecommissioned : false,
            locked: info.isLocked ? info.isLocked : false,
            itemCount: info.items.length
          }
          tempArr.push(tempObj);
        }
        else if (uniqueRowKey == 'id') {
          tempObj = {
            id: info.id,
            location: info.x + "-" + info.y + "-" + info.z,
            binId: info.binId,
            skuId: info.skuId,
            isDeep: info.isDeep ? info.isDeep : false,
            isPickGate: info.isPickGate ? info.isPickGate : false,
            isInwardGate: info.purpose.includes("Inward") ? true : false,
            isEnabled: info.isDisabled ? false : true,
            isLocked: info.lockedBy !== undefined ? false : true,
          };
          tempArr.push(tempObj);
        }
        else if (uniqueRowKey == 'skuId') {
          tempObj = {
            skuId: info.skuId,
            vendor: info.vendor,
            quantity: info.quantity,
            description: info.description,
            inductQuantity: info.inductQuantity,
            lowInventoryThreshold: info.lowInventoryThreshold,
            quantityToTopUp: info.quantityToTopUp
          };
          tempArr.push(tempObj);
        }
      }
      let colListArr: any = [];
      for (let property in tempArr[0]) {
        let words = property.split(/(?=[A-Z0-9])/);
        let displayFormat = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        displayFormat += " " + words.slice(1).join('');
        if (property.length == 3) {
          displayFormat = property.toUpperCase()
        }
        colListArr.push(displayFormat);
      }
      setColumnList(colListArr);
      setManualMapRes(tempArr);
      setLocationSearch(newValue);
    }
  };

  const clickOption = (value: string) => {
    let str = `${value} : `;
    setLocationSearch(str);
  };

  const handleChange = (val: any, sorter: any) => {
    let SortableData = alldata;
    if (locationSearch) {
      SortableData = finalRes;
    }
    const sortOut = SortableData.sort((a: any, b: any) => {
      if (sorter.order == "ascend") {
        if (sorter.field == "date") {
          return moment(a[sorter.columnKey]).unix() - moment(b[sorter.columnKey]).unix()
        }
        else if(sorter.field == "number")
        {
          if(sorter.columnKey === "items")
          {
            return a[sorter.columnKey].length - b[sorter.columnKey].length
          }
          else{
            return a[sorter.columnKey] - b[sorter.columnKey]
          }
          
        }
        else {
          return a[sorter.columnKey]?.localeCompare(b[sorter.columnKey]?b[sorter.columnKey]:"")
        }

      }
      else {
        if (sorter.field == "date") {
          return moment(b[sorter.columnKey]).unix() - moment(a[sorter.columnKey]).unix()
        }
        else if(sorter.field == "number")
        {
          if(sorter.columnKey === "items")
          {
            return b[sorter.columnKey].length - a[sorter.columnKey].length
          }
          if(sorter.columnKey === "x")
          {
              return b[sorter.columnKey] - a[sorter.columnKey]
          }
          else{
            return b[sorter.columnKey] - a[sorter.columnKey]
          }
        }
        else {
          
          return b[sorter.columnKey]?.localeCompare(a[sorter.columnKey] ? a[sorter.columnKey] : "")
        }
      }
    });
    let _data = sortOut.slice(0, searchDataSource.length);
    setSearchDataSource(_data);
  }

  return (
    <Styles.Container>
      <Styles.TableContainer>
        <Styles.TableHead>
          {isSearchBoxHided ?
            <Styles.MainTitleStyle>Order Process Details</Styles.MainTitleStyle> :
            <Styles.searchBox width={headerAction === undefined ? "full-width" : ""}>
              <Input
                size="large"
                placeholder="Filter / Search By"
                allowClear
                value={locationSearch}
                prefix={<Filter />}
                onChange={handleCategoryNameInputChange}
              />
              <div className="search-dropdown-box">
                {columnList
                  .filter((item: any) => {
                    const searchTerm = locationSearch.toLowerCase();
                    const columnVal = item.toLowerCase();
                    return (
                      searchTerm &&
                      columnVal.startsWith(searchTerm) &&
                      columnVal !== searchTerm
                    );
                  })
                  .map((item) => (
                    <div
                      onClick={() => clickOption(item)}
                      className="search-dropdown-row"
                      key={item}
                    >
                      {item}
                    </div>
                  ))}
              </div>

            </Styles.searchBox>
          }

          {headerAction}

        </Styles.TableHead>
        <Styles.TableBody>
          <Table
            tableLayout="fixed"
            rowKey={uniqueRowKey}
            columns={column}
            locale={emptyText}
            dataSource={searchDataSource}
            pagination={false}
            className="global-table"
            showSorterTooltip={false}
            loading={loader}
            sticky={true}
            onChange={(val: any, filter: any, sorter: any, extra: any) => {
              handleChange(val, sorter);
            }}
            footer={() =>
              <>
                {
                  searchDataSource && searchDataSource.length > 0 && !isSearchBoxHided && (
                    <Styles.Bottom>
                      {locationSearch == "" && finalRes && finalRes.length == 0 ?
                        <Styles.pageText>
                          Showing <span className="paginationVal">{searchDataSource && searchDataSource.length > 0 ? 1 : 0}</span> to <span className="paginationVal">{searchDataSource.length}</span> of <span className="paginationVal">{alldata.length}</span>{" "}
                          entries
                        </Styles.pageText> :
                        <Styles.pageText>
                          {finalRes && finalRes.length == 0 ?
                            "" :
                            <>
                              Showing <span className="paginationVal">{searchDataSource && searchDataSource.length > 0 ? 1 : 0}</span> to <span className="paginationVal">{searchDataSource && searchDataSource.length}</span> of <span className="paginationVal">{finalRes && finalRes.length}</span>{" "}
                              entries {paginationText !== "" ? <>{paginationText} <span className="paginationVal">{alldata && alldata.length}</span>{")"}</> : ""}
                            </>}
                        </Styles.pageText>
                        // <></>
                      }
                      {viewLink && (
                        <Styles.viewMore>
                          {uniqueRowKey === "id" ? 
                          <a onClick={viewMoreSlot}>View More...</a>
                          :
                          <a onClick={viewMore}>View More...</a>
                          }
                        </Styles.viewMore>
                      )}
                    </Styles.Bottom>
                  )
                }
              </>
            }
          />
        </Styles.TableBody>
      </Styles.TableContainer>
    </Styles.Container>
  );
}
