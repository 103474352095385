import * as Styles from "./styles";
import { Select, Tag, DatePicker, Modal, Button, Table } from "antd";
import CsvDownloader from "react-csv-downloader";
import { ReactComponent as Close } from "../../icons/close.svg";
export interface pickupProps {
  isOkPopup: any;
   isCloseOkPopup: any;
   isCancelOkPopup: any;
   exportDataSource: any;
   exportcolumns: any;
   exportColumn: any;
   loader: any;
   showTotal: any;
}
export default function PickupSKUs({isOkPopup, isCloseOkPopup, isCancelOkPopup, exportDataSource, exportcolumns, exportColumn, loader, showTotal} : pickupProps ) {
  return (
    <Styles.ModalView>
    <Modal
      wrapClassName="operator"
      open={isOkPopup}
      onOk={isCloseOkPopup}
      onCancel={isCancelOkPopup}
      closable={false}
      width={'100%'}
      className="modal-style"
      footer={[
        exportDataSource.length > 0 &&
        <Styles.exportRow>
        <Button key="back" style={{background:'#FF8B3D', color: "#FFFFFF", fontSize: "16px", fontWeight:500}} onClick={isCancelOkPopup}>
          Cancel
        </Button>,
        
          <Styles.exportCSV>
            <CsvDownloader
              filename={"External-SKU-Unfulfilled-Orders"}
              columns={exportcolumns}
              datas={exportDataSource}
              style={{background:'#07A568'}}
              text="Download CSV"
            />
          </Styles.exportCSV>
        
      </Styles.exportRow>
      
      ]}
    >
      {/* <Button style={{background:'#07A568'}} key="submit" type="primary" onClick={isCloseOkPopup}>
          Download CSV
        </Button> */}
      <Styles.Head>
        External SKUs that need to be picked across all unfulfilled orders
        <a onClick={isCancelOkPopup}><Close /></a>
        </Styles.Head>
      <Styles.Content>
      <Table
        tableLayout="fixed"
        columns={exportColumn}
        dataSource={exportDataSource}
        className="global-table"
        showSorterTooltip={false}
        loading={loader}
        sticky={true}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], showTotal: showTotal}}
        bordered={false}
      />
      </Styles.Content>
    </Modal>
    </Styles.ModalView>
  );
}
