import { ReactComponent as BinIcon } from "./icons/bin-icon.svg";
import { ReactComponent as OrderIcon } from "./icons/order-icon.svg";
import { ReactComponent as HomeIcon } from "./icons/home-icon.svg";
import { ReactComponent as SkuIcon } from "./icons/sku-icon.svg";
import { ReactComponent as SlotIcon } from "./icons/slot-icon.svg";
import * as Styles from "./styles";

export default function Sidebar() {
  return (
    <Styles.Sidebar>
      <Styles.NLink to="/dashboard"
        className={
          location?.pathname.includes("dashboard") ||
            location?.pathname === "/"
            ? "active"
            : "none"
        }
      >
        <HomeIcon />
      </Styles.NLink>
      <Styles.NLink
        to="/order_list"
        className={
          location?.pathname.includes("order_list") ||
            location?.pathname.includes("order_details")
            ? "active"
            : "none"
        }
      >
        <OrderIcon />
      </Styles.NLink>
      <Styles.NLink
        to="/bin_list"
        className={
          location?.pathname.includes("bin_list") ||
            location?.pathname.includes("bin_details")
            ? "active"
            : "none"
        }
      >
        <BinIcon />
      </Styles.NLink>
      <Styles.NLink
        to="/sku_list"
        className={
          location?.pathname.includes("sku_list") ||
            location?.pathname.includes("sku_details")
            ? "active"
            : "none"
        }
      >
        <SkuIcon />
      </Styles.NLink>
      <Styles.NLink
        to="/slot_list"
        className={
          location?.pathname.includes("slot_list") ||
            location?.pathname.includes("slot_details")
            ? "active"
            : "none"
        }
      >
        <SlotIcon />
      </Styles.NLink>
    </Styles.Sidebar>
  );
}
