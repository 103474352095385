import { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./styles";
import { Select, Space, Button, Input, TableColumnType, GetRef } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { FilterDropdownProps } from "antd/es/table/interface";
import { ReactComponent as TickTrue } from "./icons/tick-true.svg";
import { ReactComponent as TickFalse } from "./icons/tick-false.svg";
import { ReactComponent as LockTrue } from "./icons/lock-true.svg";
import { ReactComponent as LockFalse } from "./icons/lock-false.svg";
import axios from "../../common/api/axios";
import GlobalTable from "../../common/global-table/GlobalTable";
type InputRef = GetRef<typeof Input>;
interface DataType {
  binId: string;
  skuId: string;
  slotAssigned: boolean;
  isDecommissioned: boolean;
  isLocked: boolean;
  lockedBy: string;
  items: any;
}
type DataIndex = keyof DataType;
export default function BinListing() {
  const navigate = useNavigate();
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [viewCnt, setViewCnt] = useState(0);
  const [loader, setLoader] = useState(true);
  const [viewLink, setViewLink] = useState(true);

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "binId",
      key: "binId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "binId"
        );
        return (
          <Styles.ArrowContainer>
            Bin ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.binId.localeCompare(b.binId),
      render: (text, record) => (
        <Styles.BinId>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.BinId>
      ),
      defaultSortOrder: "ascend",
    },
    {
      dataIndex: "items",
      key: "items",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Sku ID</Styles.ArrowContainer>;
      },
      render: (_, { items }) => {
        return items.length > 0 ? items[0].skuId : "";
      },
    },
    {
      dataIndex: "slotAssigned",
      key: "slotAssigned",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Slot Assigned</Styles.ArrowContainer>;
      },
      render: (_, { slotAssigned }) => {
        return slotAssigned ? <TickTrue /> : <TickFalse />;
      },
    },
    {
      dataIndex: "isDecommissioned",
      key: "isDecommissioned",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Decommissioned</Styles.ArrowContainer>;
      },
      render: (_, { isDecommissioned }) => {
        return isDecommissioned ? <TickTrue /> : <TickFalse />;
      },
    },
    {
      dataIndex: "isLocked",
      key: "isLocked",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Locked</Styles.ArrowContainer>;
      },
      render: (_, { isLocked }) => {
        return isLocked ? <LockTrue /> : <LockFalse />;
      },
    },
    /*  {
        key: "lockedBy",
        dataIndex: "lockedBy",
        title: (titleProps) => {
          const sortedColumn = titleProps.sortColumns?.find(
            ({ column }) => column.key === "lockedBy"
          );
          return (
            <Styles.ArrowContainer>
              Locked By
              {sortedColumn?.order === "ascend" ? (
                <Styles.ArrowDownIcon />
              ) : (
                <Styles.ArrowUpIcon />
              )}
            </Styles.ArrowContainer>
          );
        },
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b) => (a?.lockedBy ?? "").localeCompare(b?.lockedBy ?? ""),
        render: (_, { lockedBy }) => {
          return (
            lockedBy ? lockedBy : ''
          );
        },
      }, */
    {
      dataIndex: "number",
      key: "items",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "items"
        );
        return (
          <Styles.ArrowContainer>
            Item Count
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.items.length - b.items.length,
      render: (_, { items }) => {
        return items.length;
      },
    },
  ];

  const redirectDetails = (clickedItem: string) => {
    navigate(`/bin_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };

  const getBinDetailsData = async () => {
    try {
      const res = await axios.get("BinMaster/bins", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if (res.data && res.data.length <= 10) {
          setViewLink(false);
        } else {
          setViewLink(true);
        }
        setAllDataSource(res.data);
        let data = res.data.slice(0, 10);
        setSearchDataSource(data);
        setSearchData(data);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBinDetailsData();
  }, []);

  return (
    <Styles.Container>
      <Sidebar />
      <Styles.BlockContainer>
<GlobalTable
            column={columns}
            alldata={allDataSource}
            data={searchDataSource}
            uniqueRowKey="binId"
            viewlink={viewLink}
            loader={loader}                    
          />
          </Styles.BlockContainer>
    </Styles.Container>
  );
}
