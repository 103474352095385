import * as Styles from "./styles";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts';
import { title } from "process";

export interface ChartsProps {
  lineChartEnable: boolean;
  barChartEnable: boolean;
  xAxisData: any;
  chartSeriesData: any;
}

function formatCount(count: any, withAbbr = true, decimals = 2) {
  if (parseInt(count) === 0) {
    return 0;
  }
  const COUNT_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];
  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result: any = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
}

export default function Charts({
  lineChartEnable,
  barChartEnable,
  xAxisData,
  chartSeriesData
}: ChartsProps) {

  let _series: any = [];

  if (barChartEnable && lineChartEnable) {
    _series = [
      {
        name: 'Line',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: '#65CC91'
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#65CCAF9E'
            },
            {
              offset: 1,
              color: '#65CCAF1A'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: chartSeriesData
      },
      {
        name: 'Bar',
        type: 'bar',
        itemStyle: {
          color: '#65CCAF',
          borderRadius: [7, 7, 0, 0]
        },
        emphasis: {
          itemStyle: {
            color: '#1F5454',
            focus: 'series'
          }
        },
        barWidth: 20,
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        data: chartSeriesData
      }
    ];
  } else if (barChartEnable) {
    _series = [{
      name: 'Bar',
      type: 'bar',
      itemStyle: {
        color: '#65CCAF',
        borderRadius: [7, 7, 0, 0]
      },
      emphasis: {
        itemStyle: {
          color: '#1F5454',
          focus: 'series'
        }
      },
      barWidth: 20,
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      data: chartSeriesData
    }]
  } else if (lineChartEnable) {
    _series = [{
      name: 'Line',
      type: 'line',
      smooth: true,
      lineStyle: {
        width: 2,
        color: '#65CC91'
      },
      showSymbol: false,
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#65CCAF9E'
          },
          {
            offset: 1,
            color: '#65CCAF1A'
          }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: chartSeriesData
    }]
  }
  const options = {
    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      padding: 25
    },
    grid: {
      top: '10%',
      left: '2%',
      right: '4%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [
      {
        show: _series.length > 0,
        type: 'category',
        boundaryGap: ['0', '20%'],
        axisLine: {
          lineStyle: {
            color: '#E6EDFF'
          }
        },
        axisLabel: {
          color: "#7C8DB5",
          interval: 0,
          rotate: -45
        },
        data: xAxisData
      }
    ],
    yAxis: [
      {
        show: _series.length > 0,
        type: 'value',
        axisLabel: {
          formatter: function (params: any) {
            return formatCount(params);
          },
          color: "#7C8DB5"
        }
      }
    ],
    series: _series,
    title:{
      show: _series.length === 0,
      textStyle: {
          color: "grey",
          fontSize: 20
      },
      text: "No data",
      left: "center",
      top: "center"
    }
  };

  return (
    <Styles.Container>
      <ReactECharts
        notMerge={true}
        option={options}
        style={{
          height: "350px",
          width: "100%",
        }}
      />
    </Styles.Container>
  );
}
