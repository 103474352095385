import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Sidebar = styled.div`
  position: fixed;
  top: 100px;
  z-index: 1000;
  // min-height: 431px;
  height: calc(100% - 144px);
  // height: 100%;


  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const NLink = styled(NavLink)`
  font-weight: 500;
  font-size: 20px;
  color: #003c3c;
  padding: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  border-left: 5px solid #fff;

  :hover {
    color: #003c3c;
  }

  &.active {
    background: #e0fbee;
    border-left: 5px solid #07a568;
    width: 100%;
    color: #ffffff;
    svg {
      fill: #1f5454;
    }
  }

  svg {
    fill: #666;
  }
`;
