import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  .global-table {
    table {
      border-radius: 0px;
    }
    .ant-table-header {
      border-radius: 0px;
    }
    thead th {
      background: #f3fdf8;
      font-weight: 600;
      font-size: 16px;
      color: #1f5454;
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-color: #07a568;
      border-radius: 0px !important;
      padding: 9px 10px;
      &::before {
        width: 0px !important;
      }
    }
    thead th.ant-table-column-sort {
      background: rgb(243, 253, 248) !important;
    }
    tbody td {
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      border-bottom: 0px;
      height: 20px;
      padding: 9px 10px;
    }
    .ant-table-footer {
      position: fixed;
      top: calc(100vh - 90px);
      width: 100%;
      background: #fff;
      padding: 0px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;
export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;

export const Head = styled.div`
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
`;

export const viewMore = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
  width: 100%;
  text-decoration-color: #444;
  a {
    color: #444444;
  }
  a:hover {
    color: #000;
  }
`;

export const Bottom = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 10px;
  align-items: center;
  border-top: 1px solid rgb(204 204 204 / 43%);
`;
export const pageText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 80%;
`;

export const TableHead = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space:nowrap;
  column-gap:15px;

`;

export const searchBox = styled.div<{width:String;}>`
  position: relative;
  width:${(props) => (props.width === 'full-width' ? '100%' : '70%')};
  min-width: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #ebebeb;
  background-image: none;
  border-radius: 6px;
  transition: all 0.2s;
  .ant-input-affix-wrapper {
    background: #f8f8f8;
    border-radius: 6px;
    border-color: #ebebeb;
  }
`;

export const MainTitleStyle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
`;

export const TableContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 100%;
`;

export const TableBody = styled.div`
  height: calc(100vh - 245px);
  overflow-y: auto;
  border-radius: 0px 0px 8px 8px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .tag {
    border-radius: 10px;
    padding: 0px 10px;
  }
`;