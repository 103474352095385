import React, { useEffect, useState } from "react";
import type { TabsProps } from 'antd';
import Sidebar from "../../components/sidebar/Sidebar";
import { Select, Tag, DatePicker, Tabs, Button, Divider, Spin } from "antd";
import type { DatePickerProps, GetProps } from "antd";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import axios from "axios";
import GlobalTable from "../../common/global-table/GlobalTable";
import moment from "moment";
import { ReactComponent as Empty } from "./icons/empty.svg";
import * as Styles from "./styles";
import { LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import KpiCards from "./components/kpi-cards/KpiCards";
import Charts from "./components/charts/Charts";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

interface DataType {
  orderId: string;
  fulfillmentStart: string;
  fulfillmentComp: string;
  TAT: string;
  SKU: string;
}

export default function Dashboard() {
  
  let storageFilter = localStorage.getItem('filter') === null ? "today" : localStorage.getItem('filter') ;
  const [currentTabKey, setCurrentTabKey] = useState("1");
  const [lineChartIconActive, setLineChartIconActive] = useState(true);
  const [barChartIconActive, setBarChartIconActive] = useState(true);
  const [xAxisData, setXAxisData] = useState([]);
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageLoader, setPageLoader] = useState(true);
  const [viewLink, setViewLink] = useState(true);
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const [filter, setFilter] = useState(storageFilter);
  const [chartFilter, setChartFilter] = useState("week");
  const [filterType, setFilterType] = useState("under");
  const [metricName, setMetricName] = useState("OrderCreatedEvent");
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(false);
  const [chartDateRangePickerVisibility, setChartDateRangePickerVisibility] = useState(false);
  const [snippetInfo, setSnippetInfo] = useState({});
  const [startDate, setStartDate] = useState(""
    //moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(""
    //moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [cardStartDate, setCardStartDate] = useState(
    moment().subtract(24, 'hours').format("YYYY-MM-DD HH:mm:ss")
  );
  const [cardEndDate, setCardEndDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const { RangePicker } = DatePicker;
  useEffect(() => {
    getDashboardDetailsData();
    getNonZeroData();
  },[])
  useEffect(() => {
    if (filter !== "") {
      setPageLoader(true);
      handleDurationChange(filter);
     
    }
  }, [filter]);
  useEffect(() => {
    if(startDate)
    {
      getChartDetails(currentTabKey, metricName);
    }
  }, [startDate]);
  const getNonZeroData = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}api/MasterData/skus`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
          WaveId: 1,
          FulfillmentId: 1,

        },
      });
      if (res.status === 200) {
        let filterData = res.data.filter((val:any) => val.quantity > 0 && val.storedInCartePlus === true)
        setSnippetInfo({
          ...snippetInfo,
          nonZeroQty: filterData.length
        });
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  }
  /* useEffect(() => {
    if (chartFilter !== "") {
      getChartDetails(currentTabKey, metricName);
    }
  }, [chartFilter]); */

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Order Fulfilled',
      children: (<>
        {xAxisData.length > 0 && chartSeriesData.length > 0 ?
          <Charts
            lineChartEnable={lineChartIconActive}
            barChartEnable={barChartIconActive}
            xAxisData={xAxisData}
            chartSeriesData={chartSeriesData}
          />
          : <Styles.NoData>
          <p>
          <Empty />
          </p>
          No Data
          </Styles.NoData>}
      </>)
    },
    {
      key: '2',
      label: 'Picks Count',
      children: (<>
        {xAxisData.length > 0 && chartSeriesData.length > 0 ?
          <Charts
            lineChartEnable={lineChartIconActive}
            barChartEnable={barChartIconActive}
            xAxisData={xAxisData}
            chartSeriesData={chartSeriesData}
          />
          : <Styles.NoData>
          <p>
          <Empty />
          </p>
          No Data
          </Styles.NoData>}
      </>)
    },
    {
      key: '3',
      label: 'Throughput Statistics',
      children: (<>
        {xAxisData.length > 0 && chartSeriesData.length > 0 ?
          <Charts
            lineChartEnable={lineChartIconActive}
            barChartEnable={barChartIconActive}
            xAxisData={xAxisData}
            chartSeriesData={chartSeriesData}
          />
          : <Styles.NoData>
            <p>
            <Empty />
            </p>
            No Data
            </Styles.NoData>}
      </>)
    }
  ];

  const getCardData = async(metricData:any) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_METRICS_API_URL}getEventAggregation?name=${metricData}&startDateTimeUtc=${cardStartDate}&endDateTimeUtc=${cardEndDate}&timeGranularity=None`, {
        headers: {
          Accept: "application/json"
        },
      });
      if (res.status === 200) {
        const nextList:any = snippetInfo;
        nextList[metricData] = res.data[0].count; // Problem: mutates list[0]
        setSnippetInfo(nextList);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  }
  const getDashboardDetailsData = async () => {
    
    let metricArray = ["OrderCreatedEvent", "PickCompletedEvent", "OrderFulfilledEvent"];
    metricArray.forEach((val) => {
      getCardData(val);
    })
    

    let sampleData: any = [
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      },
      {
        "orderId": "SO27894628",
        "fulfillmentStart": "11:59:00",
        "fulfillmentComp": "12:08:10",
        "TAT": "550.41 Sec",
        "SKU": "500707"
      }
    ];
    let sortData = sampleData;
    if (sampleData && sampleData.length <= 10) {
      setViewLink(false);
    } else {
      setViewLink(true);
    }
    setAllDataSource(sortData);
    let data = sortData.slice(0, 10);
    setSearchDataSource(data);
  };

  const getChartDetails = async (currentTabKey: String, metricName: String) => {
    setXAxisData([]);
    setChartSeriesData([]);
    let timeGranularity = filter === "today" ? "Hour" : "Day";
    let xAxisData: any;
    let valData: any;
    try {
      const res = await axios.get(`${process.env.REACT_APP_METRICS_API_URL}getEventAggregation?name=${metricName}&startDateTimeUtc=${startDate}&endDateTimeUtc=${endDate}&timeGranularity=${timeGranularity}`, {
        headers: {
          Accept: "application/json"
        },
      });
      if (res.status === 200) {
        // setChartFilter("");
        // setChartStartDate("");
        // setChartEndDate("");
       /*  let apiresxAxis = res.data.map((obj: any) => obj.dimensions.orderReferenceId).join(', ');
        // xAxisData = apiresxAxis.split(",").map((value: any) => parseInt(value.trim(), 10));
        xAxisData = apiresxAxis.split(",");
        let apiresval = res.data.map((obj: any) => obj.value).join(', ');
        let tempData = apiresval.split(",").map((value: any) => parseInt(value.trim(), 10)); */
        let cumulativeData = [];
        let xAxisVal:any = [];
        let sum = 0;
        for (let i = 0; i < res.data.length; i++) {
          sum = res.data[i].count;
          let xData;
          if(timeGranularity === "Hour")
          {
            xData = moment(res.data[i].dateTimeUtc).format("HH:mm")
          }
          else{
            xData = moment(res.data[i].dateTimeUtc).format("MMM DD")
          }
          cumulativeData.push(sum);
          xAxisVal.push(xData);
        }
        valData = cumulativeData;
        setXAxisData(xAxisVal);
        setChartSeriesData(valData);
        setLoader(false);
        setPageLoader(false);
      }
    } catch (error: any) {
      setPageLoader(false);
      setLoader(false);
    }
                                                // ## Sample Dataset ##
    // xAxisData = ['Jan 1', 'Jan 8', 'Jan 15', 'Jan 22', 'Jan 29', 'Feb 5', 'Feb 12', 'Feb 19', 'Feb 26', 'Mar 4', 'Mar 11'];
    // let cumulativeData = [];
    // let sum = 0;
    // let valData1 = [3000, 1000, 5000, 4049, 9000, 3400, 250, 7300, 6700, 1500, 8000];
    // for (let i = 0; i < valData1.length; i++) {
    //   sum += valData1[i];
    //   cumulativeData.push(sum);
    // }
    // valData = cumulativeData;
    // setXAxisData(xAxisData);
    // setChartSeriesData(valData);
  }

  const onMainTabChange = async (key: string) => {
    setLoader(true);
    // setTimeout(async () => {
    setCurrentTabKey(key);
    // }, 1000);
    if (key == "1") {
      setMetricName("OrderCreatedEvent");
      await getChartDetails(key, "OrderCreatedEvent");
    } else if (key == "2") {
      setMetricName("PickCompletedEvent");
      await getChartDetails(key, "PickCompletedEvent");
    } else if (key == "3") {
      setMetricName("");
      await getChartDetails(key, "");
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "orderId",
      key: "orderId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "orderId"
        );
        return (
          <Styles.ArrowContainer>
            Order ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.orderId ?? "").localeCompare(b?.orderId ?? ""),
      render: (text, record) => (
        <>{text}</>
      ),
      defaultSortOrder: "ascend"
    },
    {
      dataIndex: "fulfillmentStart",
      key: "fulfillmentStart",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "fulfillmentStart"
        );
        return (
          <Styles.ArrowContainer>
            Fulfillment Start
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.fulfillmentStart ?? "").localeCompare(b?.fulfillmentStart ?? ""),
      render: (text, record) => (
        <>{text}</>
      ),
    },
    {
      dataIndex: "fulfillmentComp",
      key: "fulfillmentComp",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "fulfillmentComp"
        );
        return (
          <Styles.ArrowContainer>
            Fulfillment Completed
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.fulfillmentComp ?? "").localeCompare(b?.fulfillmentComp ?? ""),
      render: (text, record) => (
        <>{text}</>
      ),
    },
    {
      dataIndex: "TAT",
      key: "TAT",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "TAT"
        );
        return (
          <Styles.ArrowContainer>
            TAT
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.TAT ?? "").localeCompare(b?.TAT ?? ""),
      render: (text, record) => (
        <>{text}</>
      ),
    },
    {
      dataIndex: "SKU",
      key: "SKU",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "SKU"
        );
        return (
          <Styles.ArrowContainer>
            SKU
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a?.SKU ?? "").localeCompare(b?.SKU ?? ""),
      render: (text, record) => (
        <>{text}</>
      ),
    }
  ];

  const handleDurationChange = (value: any) => {
    let startOfWeek = "";
    let endOfWeek = "";
    setDateRangePickerVisibility(false);
    if (value === "week") {
      startOfWeek = moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm:ss');
      //startOfWeek = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "month") {
      startOfWeek = moment().subtract(30, 'd').format('YYYY-MM-DD HH:mm:ss');
      //startOfWeek = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "today") {
      startOfWeek = moment().subtract(24, 'hours').format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().format("YYYY-MM-DD HH:mm:ss");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
    if (value === "custom") {
      setDateRangePickerVisibility(true);
    } else {
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
      setFilter(value);
      localStorage.setItem('filter', value)
    }
  }

/*   const handleChartDurationChange = (value: string) => {
    let startOfWeek = "";
    let endOfWeek = "";
    setChartDateRangePickerVisibility(false);
    if (value === "week") {
      startOfWeek = moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm:ss');
      //startOfWeek = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss");
      endOfWeek = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setChartStartDate(startOfWeek);
      setChartEndDate(endOfWeek);
    }
    if (value === "daterange") {
      setChartDateRangePickerVisibility(true);
    } else {
      setChartStartDate(startOfWeek);
      setChartEndDate(endOfWeek);
      setChartFilter(value);
    }
  } */

  const handleTypeChange = (value: string) => {
    setFilterType(value);
  }

  const handleDatePicker = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    setFilter("custom");
  };

 /*  const handleChartDatePicker = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setChartStartDate(dateString[0]);
    setChartEndDate(dateString[1]);
    setChartFilter("daterange");
  }; */

  const lineChartHandle = () => {
    setLineChartIconActive(!lineChartIconActive);
  };

  const barChartHandle = () => {
    setBarChartIconActive(!barChartIconActive);
  };

  return (
    <Styles.Container>
      <Sidebar />
      <Styles.BlockContainer>
        <Styles.TopPanelContainer>
          <Styles.MainTitleStyle>Overview Details</Styles.MainTitleStyle>
          {/* <Styles.SelectBoxPanel>
            <Select
              variant="borderless"
              style={{ textAlign: "right" }}
              className="common arrow-right"
              popupClassName="pick-dropdown"
              defaultValue={filter}
              onChange={handleDurationChange}
              options={[
                // { value: "today", label: "Last 24 hrs" },
                // { value: "month", label: "Last 30 days" },
                // { value: "week", label: "Last 7 days" },
                // { value: "custom", label: "Custom" }
                { value: "today", label: "Last 24 hrs" },
                { value: "week", label: "Last 7 days" },
                { value: "month", label: "Last 30 days" },
                { value: "custom", label: "Custom" },
              ]}
            />
            {dateRangePickerVisibility && (
              <RangePicker showTime onChange={handleDatePicker} />
            )}
          </Styles.SelectBoxPanel> */}
        </Styles.TopPanelContainer>
        <KpiCards kpiInfo={snippetInfo} />
        <Styles.RowDivider />
        <Styles.GraphicalSection>
          {loader ? <Styles.CenterSpin><Spin /></Styles.CenterSpin> : ""}
          <Tabs defaultActiveKey="1" items={items} onChange={onMainTabChange} tabBarExtraContent={
            <>
             {/*  <Styles.TopChartIconSelection>
                <LineChartOutlined className="icon" onClick={lineChartHandle} style={{ color: lineChartIconActive ? "#07A568" : "#CCCCCC" }} />
                <Divider className="divider" type="vertical" />
                <BarChartOutlined className="icon" onClick={barChartHandle} style={{ color: barChartIconActive ? "#07A568" : "#CCCCCC" }} />
              </Styles.TopChartIconSelection>*/}
               <Styles.ChartSelectBoxPanel>
                <Select
                  variant="borderless"
                  style={{ textAlign: "right" }}
                  className="common arrow-right"
                  popupClassName="pick-dropdown"
                  defaultValue={filter}
                  onChange={handleDurationChange}
                  options={[
                    // { value: "today", label: "Last 24 hrs" },
                    // { value: "month", label: "Last 30 days" },
                    // { value: "week", label: "Last 7 days" },
                    // { value: "custom", label: "Custom" }
                    { value: "today", label: "Last 24 hrs" },
                    { value: "week", label: "Last 7 days" },
                    { value: "month", label: "Last 30 days" },
                    { value: "custom", label: "Custom" },
                  ]}
                />
                {dateRangePickerVisibility && (
                  <RangePicker showTime onChange={handleDatePicker} />
                )}
              </Styles.ChartSelectBoxPanel> 
            </>
          } />
        </Styles.GraphicalSection>
       {/*  <Styles.RowDivider />
        <GlobalTable
          emptyText={{
            emptyText: (
              <span>
                <p>
                  <Empty />
                </p>
                No matching records. Please try changing the query and/or date filter
              </span>
            )
          }}
          isSearchBoxHided={true}
          column={columns}
          alldata={allDataSource}
          data={searchDataSource}
          viewlink={viewLink}
          loader={loader}
          headerAction={
            <>
              <Select
                variant="borderless"
                style={{ textAlign: "right" }}
                className="common arrow-right"
                popupClassName="pick-dropdown"
                defaultValue={filterType}
                onChange={handleTypeChange}
                options={[
                  { value: "under", label: "Under Processing" }
                ]}
              />
            </>
          }
        /> */}
      </Styles.BlockContainer>
      {/* <Spin spinning={pageLoader} fullscreen /> */}
    </Styles.Container>
  );
}


