import styled from "styled-components";

export const SnippetSection = styled.div`
  display: flex;
  gap: 20px;
`;

export const SnippetBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 25%;
  padding: 20px;
  position: relative;
  height: 150px;
  border: 4px solid #fff;
  :hover {
    border: 4px solid #4D9A9A;
  }
`;

export const SnippetTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #444444;
`;

export const SnippetValue = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: #07A568;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;
