import { useEffect, useState } from "react";
import OverallCards from "./components/overall-cards/OverallCards";
import ProcessTable from "../../components/process-table/ProcessTable";
import TopChart from "./components/top-chart/TopChart";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./styles";
import { Select, Tag, Input } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import { SearchTableInput } from "ant-table-extensions";
import { ReactComponent as Filter } from "./icons/filter.svg";
import { ReactComponent as TickTrue } from "./icons/tick-true.svg";
import { ReactComponent as TickFalse } from "./icons/tick-false.svg";
import { ReactComponent as LockTrue } from "./icons/lock-true.svg";
import { ReactComponent as LockFalse } from "./icons/lock-false.svg";
import axios from "../../common/api/axios";
import GlobalTable from "../../common/global-table/GlobalTable";

interface DataType {
  x: number;
  y: string;
  z: string;
  binId: string;
  skuId: string;
  purpose: string;
  isDeep: string;
  isPickGate: string;
  isInwardGate: number;
  isDisabled: number;
  lockedBy: string;
}

export default function SlotListing() {
  const navigate = useNavigate();
  const [listSelectedValue, setListSelectedValue] =
    useState("overview_details");
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const [showDataSource, setShowDataSource] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [binDataSource, setBinDataSource] = useState([]);
  const [viewCnt, setViewCnt] = useState(0);
  const [loader, setLoader] = useState(true);
  const [viewLink, setViewLink] = useState(true);

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "number",
      key: "x",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "x"
        );
        return (
          <Styles.ArrowContainer>
            Location
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      render: (x, val, index) => {
        const joinedItem = val.x + "-" + val.y + "-" + val.z;
        return (
          <Styles.Location>
            <a onClick={() => redirectDetails(joinedItem)}>{joinedItem}</a>
          </Styles.Location>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.x - b.x,
      defaultSortOrder: "ascend",
    },
    {
      dataIndex: "binId",
      key: "binId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "binId"
        );
        return (
          <Styles.ArrowContainer>
            Bin ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => (a?.binId ?? "").localeCompare(b?.binId ?? ""),
    },
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU ID
            {/* {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )} */}
          </Styles.ArrowContainer>
        );
      },
      /* sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => (a?.skuId ?? "").localeCompare(b?.skuId ?? ""), */
    },
    /* {
        dataIndex: "purpose",
        key: "purpose",
        title: (titleProps) => {
          const sortedColumn = titleProps.sortColumns?.find(
            ({ column }) => column.key === "purpose"
          );
          return (
            <Styles.ArrowContainer>
              Purpose
              {sortedColumn?.order === "ascend" ? (
                <Styles.ArrowDownIcon />
              ) : (
                <Styles.ArrowUpIcon />
              )}
            </Styles.ArrowContainer>
          );
        },
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b) => a.purpose.localeCompare(b.purpose)
      }, */
    {
      dataIndex: "isDeep",
      key: "isDeep",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "isDeep"
        );
        return <Styles.ArrowContainer>Is Deep</Styles.ArrowContainer>;
      },
      render: (_, { isDeep }) => {
        return isDeep ? <TickTrue /> : <TickFalse />;
      },
    },
    {
      key: "isPickGate",
      dataIndex: "isPickGate",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Is PickGate</Styles.ArrowContainer>;
      },
      render: (x, val, index) => {
        return val.purpose.includes("Pick") ? <TickTrue /> : <TickFalse />;
      },
    },
    {
      dataIndex: "isInwardGate",
      key: "isInwardGate",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Is Inwardgate</Styles.ArrowContainer>;
      },
      render: (x, val, z) => {
        return val.purpose.includes("Inward") ? <TickTrue /> : <TickFalse />;
      },
    },
    {
      dataIndex: "isDisabled",
      key: "isDisabled",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Is Enabled</Styles.ArrowContainer>;
      },
      render: (_, { isDisabled }) => {
        return isDisabled ? <TickFalse /> : <TickTrue />;
      },
    },
    {
      dataIndex: "lockedBy",
      key: "lockedBy",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Is Locked</Styles.ArrowContainer>;
      },
      /* sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b) => (a?.lockedBy ?? "").localeCompare(b?.lockedBy ?? ""), */
      render: (_, { lockedBy }) => {
        return lockedBy !== undefined ? <LockFalse /> : <LockTrue />;
      },
    },
  ];

  const getBinDetailsData = async () => {
    try {
      let unAssigned = false;
      const res = await axios.get(`/Slot/slots/${unAssigned}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if(res.data.length > 10)
        {
          setViewLink(true);
        }
        setAllDataSource(res.data);
        setShowDataSource(res.data);
        let data = res.data.slice(0, 10);
        let gateVal:any = [];
        /* setAllDataSource({
          ...allDataSource,
          skuId: ''
        }) */
        //setSearchDataSource(data);
        //setSearchData(data);
        //for (let index = 0; index < data.length; index++) {
          for (let index = 0; index < data.length; index++) {
            if (data[index].binId) {
              await axios
                .get(`/BinMaster/items/${data[index].binId}`, {
                  headers: {
                    Accept: "application/json",
                    tenantId: 1,
                  },
                })
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.length > 0) {
                      gateVal.push({
                        ...data[index],
                        skuId: res.data[0].skuId,
                      });
                      //allDataSource[index].skuId = res.data[0].skuId 
                      /* setAllDataSource({
                        ...setAllDataSource,
                        skuId: res.data[0].skuId,
                      }) */
                    } else {
                      gateVal.push({
                        ...data[index],
                        skuId: "",
                      });
                    }
                  } else {
                    gateVal.push({
                      ...data[index],
                      skuId: "",
                    });
                  }
                });
            } else {
              await gateVal.push({
                ...data[index],
                skuId: "",
              });
            }
            // console.log(data.length, "---", index + 1);
            //allDataSource.splice(index, 1, gateVal);
            //setSearchData(gateVal);
            if (data.length === index + 1) {
              
              setSearchDataSource(gateVal);
              setLoader(false);
              setViewCnt(20);
            }
          }
       /*  await data.forEach(async (val: any, index: any) => {
          // console.log(index);
          //const val:any = allDataSource[index];
          if (val.binId) {
            await axios
              .get(`/BinMaster/items/${val.binId}`, {
                headers: {
                  Accept: "application/json",
                  tenantId: 1,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  console.log(res.data)
                  if (res.data.length > 0) {
                    gateVal.push({
                      ...val,
                      skuId: res.data[0].skuId,
                    });
                  } else {
                    gateVal.push({
                      ...val,
                      skuId: "",
                    });
                  }
                } else {
                  gateVal.push({
                    ...val,
                    skuId: "",
                  });
                }
              });
          } else {
            await gateVal.push({
              ...val,
              skuId: "",
            });
          }
          // console.log(data.length, "---", index + 1);
          console.log('gate--',gateVal, data.length , index + 1)
          setSearchData(gateVal);
          if (data.length === index + 1) {
            console.log('gate123--',gateVal)
            //updateSearchData
            
            //setSearchData(gateVal);
            //setLoader(false);
            //setViewCnt(20);
          }
        }); */
        //setBinDataSource(gateVal);
        
        
        //setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  };
/*   const updateSearchData((data) => {
    setSearchDataSource(data);
  }) */

  function custom_sort(a:any, b:any) {
    return b?.x - a?.x
    //return a?.fulfillment.isPartial?.localeCompare(b?.fulfillment.isPartial);
    //return moment(b?.fulfillment.isPartial).unix() - moment(a?.fulfillment.isPartial).unix()
    //return new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
  }
 
  useEffect(() => {
    getBinDetailsData();
  }, []);

  /* useEffect(() => {
    setSearchDataSource(binDataSource);
    setSearchData(binDataSource);
  }, [binDataSource]); */
  const handleCategoryNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value;
    if (newValue.trim().length === 0) {
      setLocationSearch("");
      let data = allDataSource.slice(0, 10);
      setSearchDataSource(data);
      setShowDataSource(allDataSource);
    } else {
      setLocationSearch(newValue);
    }
  };
  useEffect(() => {
    let newArr: any = [];
    let showArr: any = [];
    for (let i in allDataSource) {
      let str = Object.values(allDataSource[i]).join(" ");
      let location =
        allDataSource[i]["x"] +
        "-" +
        allDataSource[i]["y"] +
        "-" +
        allDataSource[i]["z"];
      if (
        str.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1 ||
        location.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1
      ) {
        showArr.push(allDataSource[i]);
      }
    }
    if (showArr.length < 10) {
      setViewLink(false);
    }
    else{
      setViewLink(true);
    }
    setShowDataSource(showArr);
    let data = showArr.slice(0, viewCnt);
    setSearchDataSource(data);
  }, [locationSearch]);

  const viewMore = async () => {
    setLoader(true);
    let data = allDataSource.slice(0, viewCnt);
    let gateVal: any = [];
    // console.log("data---", viewCnt);
    setSearchDataSource(data);
    //setSearchData(data);
    for (let index = 0; index < data.length; index++) {
      const val: any = allDataSource[index];
      if (val.binId) {
        await axios
          .get(`/BinMaster/items/${val.binId}`, {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.length > 0) {
                gateVal.push({
                  ...val,
                  skuId: res.data[0].skuId,
                });
              } else {
                gateVal.push({
                  ...val,
                  skuId: "",
                });
              }
            } else {
              gateVal.push({
                ...val,
                skuId: "",
              });
            }
          });
      } else {
        gateVal.push({
          ...val,
          skuId: "",
        });
      }
    }
    if (viewCnt >= allDataSource.length) {
      setViewLink(false);
    }
    //setSearchData(gateVal);
    setSearchDataSource(gateVal);
    setViewCnt(viewCnt + 10);
    setLoader(false);
  };
  const redirectDetails = (clickedItem: string) => {
    navigate(`/slot_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };
  return (
    <Styles.Container>
      <Sidebar />
      <Styles.BlockContainer>
      {/* <Styles.TableContainer>
        <Styles.TableHead>
          <Styles.searchBox>
            <Input
              size="large"
              placeholder="Filter / Search By"
              allowClear
              prefix={<Filter />}
              onChange={handleCategoryNameInputChange}
            />
          </Styles.searchBox>
          
        </Styles.TableHead>
        <Styles.TableBody>
          <ProcessTable
            column={columns}
            data={searchDataSource}
            loader={loader}
            footer={
              searchDataSource.length > 0 && (
                <Styles.Bottom>
                  <Styles.pageText>
                    Showing 1 to {searchDataSource.length} of{" "}
                    {showDataSource.length} entries
                  </Styles.pageText>
                  {viewLink && (
                    <Styles.viewMore>
                      <a onClick={viewMore}>View More...</a>
                    </Styles.viewMore>
                  )}
                </Styles.Bottom>
              )
            }
          />
        </Styles.TableBody>
      </Styles.TableContainer> */}
      <GlobalTable
        uniqueRowKey="id"
        column={columns}
        alldata={allDataSource}
        data={searchDataSource}
        viewlink={viewLink}
        loader={loader}
        viewMoreSlot={viewMore}
       
      />
       </Styles.BlockContainer>
    </Styles.Container>
  );
}
