import { useEffect, useState, useRef } from "react";
import OverallCards from "./components/overall-cards/OverallCards";
import ProcessTable from "../../components/process-table/ProcessTable";
import TopChart from "./components/top-chart/TopChart";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./detailStyles";
import {
  Select,
  Space,
  Button,
  Input,
  TableColumnType,
  GetRef,
  Tag,
  message,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";

import type { FilterDropdownProps } from "antd/es/table/interface";
import { SearchTableInput } from "ant-table-extensions";
import { ReactComponent as Filter } from "./icons/filter.svg";
import { ReactComponent as CardLoaderIcon } from "../../app/icons/card_loader.svg";
import moment from "moment";
import axios from "../../common/api/axios";
type InputRef = GetRef<typeof Input>;
interface DataType {
  binId: string;
  skuId: string;
  slotAssigned: boolean;
  isDecommissioned: string;
  isLocked: boolean;
  lockedBy: string;
  items: any;
  event: string;
}
interface DetailsType {
  fulfillmentId: string;
  isFulfilledExternally: boolean;
  isFulfilled: boolean;
}
type DataIndex = keyof DataType;
export default function OrderDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = location?.state.clickedItem;
  const [orderdata, setOrderData] = useState([]);
  const [Fulfillmentdata, setFulfillmentdata] = useState([]);
  const [Externaldata, setExternaldata] = useState([]);
  const [allDetailsData, setDetailsDataSource] = useState<DetailsType>();
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState<string | undefined>(orderId);
  const [searchData, setSearchData] = useState<string | undefined>(orderId);
  const [tat, setTat] = useState("");
  const [fulFillStart, setFulfillStart] = useState("");
  const [pickStart, setPickStart] = useState("");
  const [fulFillComplete, setFulfillComplete] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const getBinDetailsData = async () => {
    if (searchText) {
      try {
        const res = await axios.get(`Wms/${searchText}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          setSearchData(searchText);
          setLoader(false);
          setSearchText("");
          setDetailsDataSource(res.data);
          setOrderData(res.data.orderItems);
          setFulfillmentdata(res.data.fulfillment.items);
          if(res.data.externalFulfillment)
          {
           setExternaldata(res.data.externalFulfillment.skus);
          }
          
          let ind = res.data.history.length - 1;
          if (res.data.history[ind].state === "Fulfilled") {
            let dateFinal: any = res.data.history[ind]
              ? new Date(res.data.history[ind].timeStamp)
              : 0;
            let dateInitial: any = res.data.history[1]
              ? new Date(res.data.history[1].timeStamp)
              : 0;
            setTat(((dateFinal - dateInitial) / 1_000).toFixed(2) + " S");
            var localTimeComplete = moment
              .utc(res.data.history[ind].timeStamp)
              .toDate();
            res.data.history[ind]
              ? setFulfillComplete(
                  moment(localTimeComplete).format("MM/DD/YYYY HH:mm:ss")
                )
              : setFulfillComplete("");
          } else {
            setTat("");
            setFulfillComplete("");
          }
          var localTime = res.data.history[1]
            ? moment.utc(res.data.history[1].timeStamp).toDate()
            : "";
          var localTimePick = res.data.history[2]
            ? moment.utc(res.data.history[2].timeStamp).toDate()
            : "";

          localTime
            ? setFulfillStart(moment(localTime).format("MM/DD/YYYY HH:mm:ss"))
            : setFulfillStart("");
          localTimePick
            ? setPickStart(moment(localTimePick).format("MM/DD/YYYY HH:mm:ss"))
            : setPickStart("");
        }
      } catch (error: any) {
        setLoader(false);
        if(error?.response?.data)
        {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
        setSearchText('');
      }
    }
  };

  useEffect(() => {
    getBinDetailsData();
  }, []);
  const Fulfillmentcolumns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) => (
        <Styles.UnderlineText>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.UnderlineText>
      ),
      defaultSortOrder: 'ascend'
    },
    {
      dataIndex: "itemId",
      key: "itemId",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Item ID</Styles.ArrowContainer>;
      },
    },
  ];

  const Externalcolumns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) =>  (
      <Styles.UnderlineText>
      <a onClick={() => redirectDetails(text)}>{text}</a>
      </Styles.UnderlineText>
      ),
      defaultSortOrder: 'ascend'
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Qty</Styles.ArrowContainer>;
      },
    },
  ];

  const Ordercolumns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) => (
        <Styles.UnderlineText>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.UnderlineText>
      ),
      defaultSortOrder: 'ascend'
    },
  ];

  const Bincolumns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) => (
        <Styles.UnderlineText>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.UnderlineText>
      ),
    },
    {
      dataIndex: "qty",
      key: "qty",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Qty</Styles.ArrowContainer>;
      },
    },
    {
      dataIndex: "binId",
      key: "binId",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Bin ID</Styles.ArrowContainer>;
      },
    },
  ];

  const Bindata:any = [
   /*  {
      skuId: "GSS043",
      qty: "5",
      binId: "TOTOEO100269",
    },
    {
      skuId: "MLL087",
      qty: "55",
      binId: "TOTOEO100269",
    }, */
  ];
  const redirectDetails = (clickedItem: string) => {
    navigate(`/sku_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };
  const linkDownload = `${process.env.REACT_APP_PRINTER_URL}getPickList/${searchData}`; 
  return (
    <Styles.Container>
      {/* <Styles.SubHead> */}
      <Sidebar />
      {/* </Styles.SubHead> */}
      <Styles.BlockContainer>
      {loader ? (
        <CardLoaderIcon />
      ) : (
        <Styles.TableContainer>
          <Styles.breadCrumb>
            <Styles.breadText>
              <a href="order_list">Home / </a>Order / <a href="order_list">Order List / </a>Order ID:{" "}
              {searchData}
            </Styles.breadText>
          </Styles.breadCrumb>
          <Styles.TableHead>
            <Styles.Title>Order ID</Styles.Title>
            <Styles.searchBox>
              <Input
                size="large"
                placeholder="Input Order ID"
                allowClear
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onPressEnter={() => getBinDetailsData()}
              />
            </Styles.searchBox>
            <Styles.exportCSV>
              <Button onClick={() => getBinDetailsData()}>Submit</Button>
            </Styles.exportCSV>
          </Styles.TableHead>
          <Styles.BoxRow>
            <Styles.LeftBox>
              <Styles.BoxHead>
                <Styles.Title>Order Status</Styles.Title>
                {allDetailsData?.isFulfilled ? (
                  <Tag color="#07A568" key="Completed" style={{padding: "5px 14px", borderRadius: 20}}>
                    <Styles.statusText>Completed</Styles.statusText>
                  </Tag>
                ) : (
                  <Tag color="#FFEDBD" key="Completed" style={{padding: "5px 14px", borderRadius: 20}}>
                    <Styles.statusText status={0}>
                      In Progress
                    </Styles.statusText>
                  </Tag>
                )}
              </Styles.BoxHead>
              <Styles.Divider />
              <Styles.TextStyle>
                Fulfillments ID: {allDetailsData?.fulfillmentId}
              </Styles.TextStyle>
              <Styles.TextStyle>Orchestration:</Styles.TextStyle>
              <Styles.TextStyle>Pick List: <a href={linkDownload} target="_blank">Download </a></Styles.TextStyle>
              <Styles.TextStyle>
                External Fulfilment Status:
                {allDetailsData?.isFulfilledExternally ? (
                  <Tag color="#07A568" key="Completed" style={{padding: "5px 14px", borderRadius: 20}}>
                    <Styles.statusText>Completed</Styles.statusText>
                  </Tag>
                ) : (
                  <Tag color="#FFEDBD" key="Completed" style={{padding: "5px 14px", borderRadius: 20}}>
                    <Styles.statusText status={0}>
                      Not Yet Started
                    </Styles.statusText>
                  </Tag>
                )}
              </Styles.TextStyle>
            </Styles.LeftBox>
            <Styles.RightBox>
              <Styles.BoxHead>
                <Styles.Title>Turnaround Time</Styles.Title>
                {tat && (
                  <Tag color="#07A568" key="Completed">
                    <Styles.statusText>{tat}</Styles.statusText>
                  </Tag>
                )}
              </Styles.BoxHead>

              <Styles.Divider />
              <Styles.TextStyle>
                Fulfilment Started: {fulFillStart}
              </Styles.TextStyle>
              <Styles.TextStyle>Pick Started: {pickStart}</Styles.TextStyle>
              <Styles.TextStyle>
                Fulfilment Completed: {fulFillComplete}
              </Styles.TextStyle>
            </Styles.RightBox>
          </Styles.BoxRow>
          <Styles.RowDivider />

          <Styles.BoxRowMulti>
            <Styles.BoxRowFull>
              <Styles.Title className="card-title">Order Details</Styles.Title>
              <Styles.TableView>
                <ProcessTable
                  column={Ordercolumns}
                  data={orderdata}
                  loader={loader}
                />
              </Styles.TableView>
            </Styles.BoxRowFull>
            <Styles.BoxRowFull>
              <Styles.Title className="card-title">
                Fulfilment Details
              </Styles.Title>
              <Styles.TableView>
                <ProcessTable
                  column={Fulfillmentcolumns}
                  data={Fulfillmentdata}
                  loader={loader}
                />
              </Styles.TableView>
            </Styles.BoxRowFull>
            <Styles.BoxRowFull>
              <Styles.Title className="card-title">
                External Fulfilment Details
              </Styles.Title>
              <Styles.TableView>
                <ProcessTable
                  column={Externalcolumns}
                  data={Externaldata}
                  loader={loader}
                />
              </Styles.TableView>
            </Styles.BoxRowFull>
          </Styles.BoxRowMulti>

         {/*  <Styles.RowDivider />

          <Styles.BoxRowFull>
            <Styles.Title className="card-title">Pick Details</Styles.Title>
            <Styles.TableView>
              <ProcessTable
                column={Bincolumns}
                data={Bindata}
                loader={loader}
              />
            </Styles.TableView>
          
          </Styles.BoxRowFull> */}
        </Styles.TableContainer>
      )}
      <>{contextHolder}</>
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
