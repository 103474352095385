import { useEffect, useState, useRef } from "react";
import OverallCards from "./components/overall-cards/OverallCards";
import ProcessTable from "../../components/process-table/ProcessTable";
import TopChart from "./components/top-chart/TopChart";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./detailStyles";
import {
  Select,
  Space,
  Button,
  Input,
  TableColumnType,
  GetRef,
  Tag,
  message,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";

import type { FilterDropdownProps } from "antd/es/table/interface";
import { SearchTableInput } from "ant-table-extensions";
import { ReactComponent as Filter } from "./icons/filter.svg";
import { ReactComponent as TickFalse } from "./icons/tick-false.svg";
import { ReactComponent as TickTrue } from "./icons/tick-true.svg";
import { ReactComponent as CardLoaderIcon } from "../../app/icons/card_loader.svg";
import { ReactComponent as LockTrue } from "./icons/lock-true.svg";
import { ReactComponent as LockFalse } from "./icons/lock-false.svg";
import axios from "../../common/api/axios";
type InputRef = GetRef<typeof Input>;
interface DataType {
  binId: string;
  skuId: string;
  slotAssigned: boolean;
  isDecommissioned: string;
  isLocked: boolean;
  lockedBy: string;
  items: any;
  event: string;
}
interface DetailsType {
  id: string;
  x: number;
  binId: string;
  y: number;
  z: number;
  purpose: string;
  group: string;
  isDisabled: boolean;
  isDeep: boolean;
  location: string;
  lockedBy: string;
}
type DataIndex = keyof DataType;
export default function SlotDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const skuId = location?.state.clickedItem;
  const [inventoryData, setInventoryData] = useState([]);
  const [allDetailsData, setDetailsDataSource] = useState<DetailsType>();
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState<string | undefined>(skuId);
  const [searchData, setSearchData] = useState<string | undefined>(skuId);
  const [messageApi, contextHolder] = message.useMessage();

  const getBinDetailsData = async () => {
    if (searchText) {
      const searchTex = searchText.split("-");
      try {
        const res = await axios.get(
          `Slot/slot/${searchTex[0]}/${searchTex[1]}/${searchTex[2]}`,
          {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          }
        );
        if (res.status === 200) {
          setDetailsDataSource(res.data);
          setInventoryData(res.data.items);
          setLoader(false);
          setSearchData(searchText);
          setSearchText("");
        }
      } catch (error: any) {
        setLoader(false);
        let msg = error?.response?.data ? error.response.data : 'No Slot found';
        messageApi.open({
          type: "error",
          content: msg,
          className: "error",
          style: {
            marginTop: "85vh",
          },
        });
        setSearchText('');
      }
    }
  };

  useEffect(() => {
    getBinDetailsData();
  }, []);

  const Bincolumns: ColumnsType<DataType> = [
    {
      dataIndex: "state",
      key: "state",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "state"
        );
        return (
          <Styles.ArrowContainer>
            State
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
    },
    {
      dataIndex: "timestamp",
      key: "timestamp",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Time Stamp</Styles.ArrowContainer>;
      },
    },
    {
      dataIndex: "binId",
      key: "binId",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Bin ID</Styles.ArrowContainer>;
      },
    },
    {
      dataIndex: "triggedby",
      key: "triggedby",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Trigged By</Styles.ArrowContainer>;
      },
    },
    {
      dataIndex: "remarks",
      key: "remarks",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Remarks</Styles.ArrowContainer>;
      },
    },
  ];

  const Bindata = [
    {
      state: "Stored",
      timestamp: "01/06/2024 22:11;59",
      binId: "TOTOEO100269",
      triggedby: "345a43c93a81470b998f80202be69d13",
      remarks: "Loreum Epsum",
    },
    {
      state: "Stored",
      timestamp: "01/06/2024 22:11;59",
      binId: "TOTOEO100269",
      triggedby: "345a43c93a81470b998f80202be69d13",
      remarks: "Loreum Epsum",
    },
    {
      state: "Stored",
      timestamp: "01/06/2024 22:11;59",
      binId: "TOTOEO100269",
      triggedby: "345a43c93a81470b998f80202be69d13",
      remarks: "Loreum Epsum",
    },
    {
      state: "Stored",
      timestamp: "01/06/2024 22:11;59",
      binId: "TOTOEO100269",
      triggedby: "345a43c93a81470b998f80202be69d13",
      remarks: "Loreum Epsum",
    },
    {
      state: "Stored",
      timestamp: "01/06/2024 22:11;59",
      binId: "TOTOEO100269",
      triggedby: "345a43c93a81470b998f80202be69d13",
      remarks: "Loreum Epsum",
    },
  ];

  return (
    <Styles.Container>
      {/* <Styles.SubHead> */}
      <Sidebar />
      {/* </Styles.SubHead> */}
      <Styles.BlockContainer>
      {loader ? (
        <CardLoaderIcon />
      ) : (
        <Styles.TableContainer>
          <Styles.breadCrumb>
            <Styles.breadText>
              <a href="order_list">Home /</a> Slot /{" "}
              <a href="slot_list">Slot List /</a> Slot ID: {searchData}
            </Styles.breadText>
          </Styles.breadCrumb>
          <Styles.TableHead>
            <Styles.Title>Slot ID</Styles.Title>
            <Styles.searchBox>
              <Input
                size="large"
                placeholder="Input Slot ID"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onPressEnter={() => getBinDetailsData()}
              />
            </Styles.searchBox>
            <Styles.exportCSV>
              <Button onClick={() => getBinDetailsData()}>Submit</Button>
            </Styles.exportCSV>
          </Styles.TableHead>
          <Styles.BoxRowFull>
            <Styles.Title>Slot Details</Styles.Title>
            <Styles.Divider />
            <Styles.TextStyle>
              Purpose: {allDetailsData?.purpose}
            </Styles.TextStyle>
            <Styles.TextStyle>Bin ID: {allDetailsData?.binId}</Styles.TextStyle>
            <Styles.BoxRow>
              <Styles.BoxHead>
                <Styles.TextStyle>Is Deep?</Styles.TextStyle>
                {allDetailsData?.isDeep ? <TickTrue /> : <TickFalse />}
              </Styles.BoxHead>
              <Styles.BoxHead>
                <Styles.TextStyle>Is Pick Station?</Styles.TextStyle>
                {allDetailsData?.purpose.includes("Pick") ? (
                  <TickTrue />
                ) : (
                  <TickFalse />
                )}
              </Styles.BoxHead>
              <Styles.BoxHead>
                <Styles.TextStyle>Is Inward Gate?</Styles.TextStyle>
                {allDetailsData?.purpose.includes("Inward") ? (
                  <TickTrue />
                ) : (
                  <TickFalse />
                )}
              </Styles.BoxHead>
              <Styles.BoxHead>
                <Styles.TextStyle>Is Enabled?</Styles.TextStyle>

                {allDetailsData?.isDisabled === false ||
                allDetailsData?.isDisabled === undefined ? (
                  <TickTrue />
                ) : (
                  <TickFalse />
                )}
              </Styles.BoxHead>
              <Styles.BoxHead>
                <Styles.TextStyle>Is Locked?</Styles.TextStyle>
                {allDetailsData?.lockedBy ? <LockFalse /> : <LockTrue />}
              </Styles.BoxHead>
            </Styles.BoxRow>
            <Styles.TextStyle>
              Locked By: {allDetailsData?.lockedBy}
            </Styles.TextStyle>
            <Styles.TextStyle>PTL Device ID: </Styles.TextStyle>
          </Styles.BoxRowFull>
          {/* <Styles.RowDivider /> */}

          {/* <Styles.BoxRowFull>
            <Styles.Title>Recent History</Styles.Title>
            <Styles.TableView>
              <ProcessTable
                column={Bincolumns}
                data={Bindata}
                loader={loader}
              />
            </Styles.TableView>
            <Styles.viewMore>
              <a>View More...</a>
            </Styles.viewMore>
          </Styles.BoxRowFull> */}
        </Styles.TableContainer>
      )}
      <>{contextHolder}</>
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
