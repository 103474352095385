import { useEffect, useState, useRef } from "react";

import { useLocation } from "react-router-dom";
import OverallCards from "./components/overall-cards/OverallCards";
import ProcessTable from "../../components/process-table/ProcessTable";
import TopChart from "./components/top-chart/TopChart";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./detailStyles";
import {
  Select,
  Space,
  Button,
  Input,
  TableColumnType,
  GetRef,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";

import type { FilterDropdownProps } from "antd/es/table/interface";
import { SearchTableInput } from "ant-table-extensions";
import { ReactComponent as Filter } from "./icons/filter.svg";
import { ReactComponent as TickTrue } from "./icons/tick-true.svg";
import { ReactComponent as TickFalse } from "./icons/tick-false.svg";
import { ReactComponent as LockTrue } from "./icons/lock-true.svg";
import { ReactComponent as LockFalse } from "./icons/lock-false.svg";
import { ReactComponent as CardLoaderIcon } from "../../app/icons/card_loader.svg";
import axios from "../../common/api/axios";
type InputRef = GetRef<typeof Input>;
interface DataType {
  binId: string;
  skuId: string;
  slotAssigned: boolean;
  isDecommissioned: string;
  isLocked: boolean;
  lockedBy: string;
  items: any;
  event: string;
  itemId: string;
}

interface DetailsType {
  binId: string;
  isDecommissioned: boolean;
  slotAssigned: boolean;
  isLocked: boolean;
  lockedBy: string;
  items: [];
}

interface ItemsType {
  skuId: string;
  itemId: number;
  length: number;
}
type DataIndex = keyof DataType;
export default function BinDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const binId = location?.state.clickedItem;
  const [inventoryData, setInventoryData] = useState<ItemsType>();
  const [allDetailsData, setDetailsDataSource] = useState<DetailsType>();
  const [loader, setLoader] = useState(true);
  const [searchText, setSearchText] = useState<string | undefined>(binId);
  const [searchData, setSearchData] = useState<string | undefined>(binId);
  const [slotDetails, setSlotDetails] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const getBinDetailsData = async () => {
    if (searchText) {
      try {
        const res = await axios.get(`BinMaster/getById/${searchText}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          setDetailsDataSource(res.data);
          setInventoryData(res.data.items);
          if(res.data.slotAssigned)
          {
            getSlotDetails();
          }
          setLoader(false);
          setSearchText("");
          setSearchData(searchText);
        }
      } catch (error: any) {
        setLoader(false);
        if(error?.response?.data)
        {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
        setSearchText('');
      }
    }
  };

  useEffect(() => {
    getBinDetailsData();
  }, []);

  const getSlotDetails = async () => {
    if (searchText) {
      try {
        const res = await axios.get(`Slot/byBin/${searchText}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          let slot = res.data.x+"-"+res.data.y+"-"+res.data.z;
          setSlotDetails(slot);
        }
      } catch (error: any) {
        setLoader(false);
        if(error?.response?.data)
        {
          messageApi.open({
            type: "error",
            content: error?.response?.data,
            className: "error",
            style: {
              marginTop: "85vh",
            },
          });
        }
        setSearchText('');
      }
    }
  };

  const redirectDetails = (clickedItem: string, page: string) => {
    if(page == "sku")
    {
    navigate(`/sku_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
    }
    else{
      navigate(`/slot_details`, {
        state: {
          clickedItem: clickedItem,
        },
      });
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            SKU
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) => (
        <Styles.UnderlineText>
          <a onClick={() => redirectDetails(text, "sku")}>{text}</a>
        </Styles.UnderlineText>
      ),
      defaultSortOrder: 'ascend'
    },
    {
      dataIndex: "itemId",
      key: "itemId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "itemId"
        );
        return (
          <Styles.ArrowContainer>
            Item ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a:any, b:any) => a.itemId - b.itemId
    },
  ];


  return (
    <Styles.Container>
      {/* <Styles.SubHead> */}
      <Sidebar />
      {/* </Styles.SubHead> */}
      <Styles.BlockContainer>
      {loader ? (
        <CardLoaderIcon />
      ) : (
        <Styles.TableContainer>
          <Styles.breadCrumb>
            <Styles.breadText>
              <a href="order_list">Home /</a> Bin /{" "}
              <a href="bin_list">Bin List /</a> Bin ID: {searchData}
            </Styles.breadText>
          </Styles.breadCrumb>
          <Styles.TableHead>
            <Styles.Title>Bin ID</Styles.Title>
            <Styles.searchBox>
              <Input
                size="large"
                placeholder="Search Bin ID"
                allowClear
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onPressEnter={() => getBinDetailsData()}
              />
            </Styles.searchBox>
            <Styles.exportCSV>
              <Button onClick={() => getBinDetailsData()}>Submit</Button>
            </Styles.exportCSV>
          </Styles.TableHead>
          <Styles.BoxRow>
            <Styles.LeftBox>
              <Styles.Title>Bin Details</Styles.Title>
              <Styles.Divider />
              Decommissioned
              {allDetailsData?.isDecommissioned ? <TickTrue /> : <TickFalse />}
              Slot Assigned
              {allDetailsData?.slotAssigned ? <Styles.UnderlineText>
                <a onClick={() => redirectDetails(slotDetails, "slot")}>{slotDetails}</a>
              </Styles.UnderlineText> :  <TickFalse />}
              Locked
              {allDetailsData?.isLocked ? <LockTrue /> : <LockFalse />}
              {allDetailsData?.lockedBy != "" &&
                `Locked By : ${allDetailsData?.lockedBy || ""}`}
            </Styles.LeftBox>
            <Styles.RightBox>
              <Styles.Title className="card-title title1">
                Inventory
              </Styles.Title>
              <Styles.TableView>
                <ProcessTable
                  column={columns}
                  data={inventoryData}
                  loader={loader}
                />
              </Styles.TableView>
              {inventoryData && inventoryData.length > 0 && 
               <Styles.pageText>
                
                    {inventoryData.length} Items
                  
                </Styles.pageText>
              }
            </Styles.RightBox>
          </Styles.BoxRow>
          {/* <Styles.RowDivider /> */}

          {/* <Styles.BoxRowFull>
        <Styles.Title>Recent History</Styles.Title>
          <Styles.TableView>
          <ProcessTable
          column={Historycolumns}
          data={Historydata} 
          loader={loader}
          />
          </Styles.TableView>
          <Styles.viewMore>
            <a >View More...</a>
          </Styles.viewMore>
        </Styles.BoxRowFull> */}

          {/* <Styles.RowDivider />

          <Styles.BoxRowFull>
            <Styles.Title className="card-title">
              Recent Bin History
            </Styles.Title>
            <Styles.TableView>
              <ProcessTable
                column={Bincolumns}
                data={Bindata}
                loader={loader}
              />
            </Styles.TableView>
            <Styles.viewMore>
              <Styles.UnderlineText>
                {" "}
                <a>View More...</a>
              </Styles.UnderlineText>
            </Styles.viewMore>
          </Styles.BoxRowFull> */}
        </Styles.TableContainer>
      )}
      <>{contextHolder}</>
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
