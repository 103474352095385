import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  // margin-top: 20px;
  height: calc(100vh - 175px);
`;

export const BlockContainer = styled.div`
width:100%;
padding-top: 68px;
padding-left: 80px;
`;

export const Divider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;

// export const SubHead = styled.div`
//   background: #ffffff;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
//   border-radius: 8px;
//   padding-top: 30px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 10px;
// `;

export const Right = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 100%;
`;
export const TableHead = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
  margin-right: 20px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;
export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;
export const statusText = styled.div<{
  status?: number;
}>`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => (props.status !== 0 ? "#07A568" : "#BD861C")};
`;

export const searchBox = styled.div`
  width: 70%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #ebebeb;
  background-image: none;
  border-radius: 6px;
  transition: all 0.2s;
  .ant-input-affix-wrapper {
    background: #f8f8f8;
    border-radius: 6px;
    border-color: #ebebeb;
  }
`;
export const viewMore = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
  width: 100%;
  text-decoration-color: #444;
  a {
    color: #444444;
  }
  a:hover {
    color: #000;
  }
`;

export const Bottom = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 10px;
  align-items: center;
  border-top: 1px solid rgb(204 204 204 / 43%);
`;
export const pageText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 80%;
`;
export const TableBody = styled.div`
  height: calc(100vh - 245px);
  overflow-y: auto;
  border-radius: 0px 0px 8px 8px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  svg {
    height: 25px;
  }
`;

export const BinId = styled.div`
  text-decoration: underline;
  text-decoration-color: #444;
  a {
    color: #444444;
  }
  a:hover {
    color: #000;
  }
`;
