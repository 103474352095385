import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import * as Styles from "./styles";
import User from "./icons/user.svg";
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Layout, Button, Dropdown } from "antd";
import { format } from "date-fns";
import Dashboard from "../pages/dashboard/Dashboard";
import OrderListing from "../pages/order-details/OrderListing";
import BinListing from "../pages/bin-details/BinListing";
import SkuListing from "../pages/sku-details/SkuListing";
import SlotListing from "../pages/slot-details/SlotListing";
import BinDetails from "../pages/bin-details/BinDetails";
import OrderDetails from "../pages/order-details/OrderDetails";
import SkuDetails from "../pages/sku-details/SkuDetails";
import SlotDetails from "../pages/slot-details/SlotDetails";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../app/app-insights";
//import FulfillmentDetails from "../pages/fulfillment-details/FulfillmentDetails";
// import AppSettings from "../pages/app-settings/AppSettings";
// import Listing from "../pages/listing/Listing";
// import Other from "../pages/other/Other";
// import Users from "../pages/listing/users/Users";

function App() {
  const { Header, Content, Footer } = Layout;
  const location = useLocation();
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);

  const onHandleSignOutClick = () => {
    window.location.replace("https://carteplus-ui-landing-test.azureedge.net/");
  };

  const items = [
    {
      key: "1",
      label: (
        <div>
          <a className="sign-out" onClick={onHandleSignOutClick}>
            Sign Out
          </a>
        </div>
      ),
    },
  ];

  return (
    <>
      <Layout>
        <div className="outerheader">
          <Header>
            <Styles.Container>
              <Styles.Left>
                <Styles.HeaderLogo />
              </Styles.Left>
              <Styles.Title>
                {location.pathname.includes("dashboard") ||
                  location?.pathname === "/"
                  ? "Supervisor Dashboard"
                  : location.pathname.includes("order_list")
                    ? "Order Listing"
                    : location.pathname.includes("order_details")
                      ? "Order Details"
                      : location.pathname.includes("bin_list")
                        ? "Bin Listing"
                        : location.pathname.includes("bin_details")
                          ? "Bin Details"
                          : location.pathname.includes("sku_list")
                            ? "Sku Listing"
                            : location.pathname.includes("sku_details")
                              ? "Sku Details"
                              : location.pathname.includes("slot_list")
                                ? "Slot Listing"
                                : location.pathname.includes("slot_details")
                                  ? "Slot Details"
                                  : ""}
              </Styles.Title>
              <Styles.Right>
                <Styles.DateContainer>
                  <Styles.Time><Moment format="hh:mm A">{time}</Moment></Styles.Time>
                  <Styles.Date><Moment format="MMM DD YYYY">{time}</Moment></Styles.Date>
                </Styles.DateContainer>
                <Dropdown
                  className="dropdown"
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  overlayClassName="header-dropdown"
                >
                  <Button>
                    <Styles.UserImage src={User} />
                    <Styles.UserContainer>
                      <Styles.UserName>Wade Willson</Styles.UserName>
                      <Styles.Role>Supervisor</Styles.Role>
                    </Styles.UserContainer>
                  </Button>
                </Dropdown>
              </Styles.Right>
            </Styles.Container>
          </Header>
        </div>
        <Layout className="main-content">
          <AppInsightsContext.Provider value={reactPlugin}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/order_list" element={<OrderListing />} />
            <Route path="/bin_list" element={<BinListing />} />
            <Route path="/bin_details" element={<BinDetails />} />
            <Route path="/order_details" element={<OrderDetails />} />
            <Route path="/sku_list" element={<SkuListing />} />
            <Route path="/sku_details" element={<SkuDetails />} />
            <Route path="/slot_details" element={<SlotDetails />} />
            <Route path="/slot_list" element={<SlotListing />} />
            {/* <Route index element={<Navigate to="fulfillment-details" />} />
            <Route path="overview-details" element={<OverviewDetails />} />
            <Route path="fulfillment-details" element={<FulfillmentDetails />} /> */}
            <Route path="*" element={<Dashboard />} />
          </Routes>
          </AppInsightsContext.Provider>
        </Layout>

        <Footer>
          {" "}
          All rights reserved © {new Date().getFullYear()} Cartesian Kinetics
        </Footer>
      </Layout>
    </>
  );
}

export default App;
