import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  .common-table {
    table {
      border-radius: 0px;
    }
    .ant-table-header {
      border-radius: 0px;
    }
    thead th {
      background: #f3fdf8;
      font-weight: 600;
      font-size: 16px;
      color: #1f5454;
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-color: #07a568;
      border-radius: 0px !important;
      padding: 9px 10px;
      &::before {
        width: 0px !important;
      }
    }
    tbody td {
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      border-bottom: 0px;
      height: 20px;
      padding: 9px 10px;
    }
    .ant-table-footer {
      // position: sticky;
      // bottom: 0;
      position: fixed;
      top: calc(100vh - 120px);
      width: 100%;
      background: #fff;
      padding: 0px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;
export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;

export const Head = styled.div`
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
`;
