import * as Styles from "./styles";
import { Select, Space, Table, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { JsxElement } from "typescript";
import { useEffect, useState } from "react";

export interface ProcessTableProps {
  column: any;
  data: any;
  loader: any;
  footer?: any;
}
export default function ProcessTable({
  column,
  data,
  loader,
  footer,
}: ProcessTableProps) {
  return (
    <Styles.Container>
      <Table
        tableLayout="fixed"
        rowKey="orderID"
        columns={column}
        dataSource={data}
        pagination={false}
        className="common-table"
        showSorterTooltip={false}
        loading={loader}
        sticky={true}
        footer={() => <>{footer}</>}
      />
    </Styles.Container>
  );
}
